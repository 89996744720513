import { useCallback } from 'react';
import { trackPromise } from 'react-promise-tracker';

import { getInsurancesApi } from '../../../common/keycloak';
import useNotifications from '../../assets/useNotifications';
import { InsuranceCreateRequest, InsuranceUpdateRequest } from '../../../generated';

/**
 * Send updated insurance state via API when the save button is pressed
 */
export default function useInsuranceApi(): {
  handleCreate: (insuranceCreateRequest: InsuranceCreateRequest, onSuccess: () => void) => void;
  handleUpdate: (
    insuranceExternalId: string,
    insuranceUpdateRequest: InsuranceUpdateRequest,
    onSuccess: () => void,
  ) => void;
} {
  const { showError, showSuccess } = useNotifications();

  /**
   * Executes an async function and processes the output
   * @param fun
   * @param description
   */
  const executeTask = useCallback(
    async function (fun: () => Promise<boolean>, description: string, onSuccess: () => void) {
      try {
        const result = await trackPromise(fun(), 'insurance-dialog');

        if (result) {
          showSuccess(`Insurance ${description}d successfully`);
          onSuccess();
        } else {
          showError(`Error while ${description}ing insurance`);
        }
      } catch (e) {
        if (e instanceof Response) {
          showError(`Error while ${description}ing insurance with status "${e.status}": ${await e.text()}`);
        } else {
          showError('Unexpected Error: ' + e);
          console.error(e);
        }
      }
    },
    [showSuccess, showError],
  );

  /**
   * Create insurance state via API
   */
  const handleCreate = useCallback(
    async function (insuranceCreateRequest: InsuranceCreateRequest, onSuccess: () => void) {
      const createInsurance = async () => {
        const insurancesApi = await getInsurancesApi();
        return insurancesApi.createInsurance(insuranceCreateRequest);
      };

      await executeTask(createInsurance, 'create', onSuccess);
    },
    [executeTask],
  );

  /**
   * Update insurance state via API
   */
  const handleUpdate = useCallback(
    async function (
      insuranceExternalId: string,
      insuranceUpdateRequest: InsuranceUpdateRequest,
      onSuccess: () => void,
    ) {
      const updateInsurance = async () => {
        const insuranceApi = await getInsurancesApi();
        return insuranceApi.updateInsurance(insuranceExternalId, insuranceUpdateRequest);
      };

      await executeTask(updateInsurance, 'update', onSuccess);
    },
    [executeTask],
  );

  return { handleCreate, handleUpdate };
}
