import React from 'react';
import get from 'lodash/get';
import { UseFormikType } from './useFormikType';
import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';

// Extend DatePicker props with formik-related properties
type FormikDatePickerProps = {
  formik: UseFormikType;
  id: string;
  label?: string;
  disableFuture?: boolean;
  disablePast?: boolean;
  disabled?: boolean;
};

/**
 * Wrapper for MUI DateTimePicker integrated with Formik
 */
export default function FormikDateTimePicker(props: FormikDatePickerProps): JSX.Element {
  const { formik, id, ...other } = props;

  if (!id) throw new Error('FormikDateTimePicker "id" property must not be empty');

  const formikError = get(formik.errors, id);
  const formikTouched = get(formik.touched, id, false);
  const helperText = formikTouched && formikError ? String(formikError) : undefined;

  return (
    <DateTimePicker
      value={get(formik.values, id, null) ? dayjs(get(formik.values, id, null)) : null}
      onChange={(date: Dayjs | null) => formik.setFieldValue(id, date)}
      slotProps={{
        textField: {
          id,
          label: props.label,
          error: formikTouched && Boolean(formikError),
          helperText: helperText,
          disabled: props.disabled,
          fullWidth: true,
          InputProps: {
            inputProps: {
              readOnly: true, // Prevent user from manually setting a value
            },
          },
        },
      }}
      views={['year', 'month', 'day', 'hours', 'minutes']}
      {...other}
    />
  );
}
