import React from 'react';
import { useTranslation } from 'react-i18next';
import { ChipProps } from '@mui/material';

import { InsuranceStatusEnum } from '../../../generated';
import ColorChip from '../../assets/ColorChip';

type InsuranceStatusChipProps = ChipProps & { insuranceStatus?: InsuranceStatusEnum };

/**
 * Show the insurance status as chip
 * @param props the status
 */
export default function InsuranceStatusChip(props: InsuranceStatusChipProps): JSX.Element {
  const { insuranceStatus, ...other } = props;

  // Trnaslations
  const { t } = useTranslation();
  const activeTxt = t('active');
  const inactiveTxt = t('inactive');
  const canceledTxt = t('canceled');

  let chipColor: 'grey' | 'green' | 'orange' = 'green';
  let label = '';
  switch (insuranceStatus) {
    case InsuranceStatusEnum.Active: {
      chipColor = 'green';
      label = activeTxt;
      break;
    }
    case InsuranceStatusEnum.Inactive: {
      chipColor = 'orange';
      label = inactiveTxt;
      break;
    }
    case InsuranceStatusEnum.Canceled: {
      chipColor = 'grey';
      label = canceledTxt;
      break;
    }
  }

  return <ColorChip chipColor={chipColor} label={label} {...other} />;
}
