import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';

export function LanguageSelector(): JSX.Element {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);

  // custom styles
  const toggleButton = {
    border: 'none !important',
    fontSize: '14px !important',
    padding: '1px 16px !important',
    textTransform: 'none',
    color: '#a8a8a8 !important',
    '&.Mui-selected': {
      backgroundColor: '#3f51b5 !important',
      color: '#fff !important',
    },
    '&:not(:first-child)': {
      borderLeft: '1px solid #3f51b5 !important',
    },
  };

  const toggleContainer = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const toggleButtonGroup = {
    border: '1px solid #3f51b5 !important',
    borderRadius: '20px !important',
    overflow: 'hidden',
    marginRight: '16px',
  };

  // event handlers
  const handleLanguageChange = (event: React.MouseEvent<HTMLElement>, newLanguage: string | null) => {
    if (newLanguage !== null) {
      setLanguage(newLanguage);
      i18n.changeLanguage(newLanguage);
    }
  };

  return (
    <Box sx={toggleContainer}>
      <ToggleButtonGroup
        value={language}
        exclusive
        onChange={handleLanguageChange}
        aria-label="language selection"
        sx={toggleButtonGroup}
      >
        <ToggleButton value="en" aria-label="english" sx={toggleButton}>
          EN
        </ToggleButton>
        <ToggleButton value="de" aria-label="german" sx={toggleButton}>
          DE
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
}

export default LanguageSelector;
