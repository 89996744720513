import React, { ReactNode } from 'react';
import TableRow from '@mui/material/TableRow';
import { TableCell } from '@mui/material';

export const DetailsEntry: React.FunctionComponent<{ property: string; children?: ReactNode }> = function (props) {
  const cellStyle: React.CSSProperties = {
    borderBottom: 0,
    verticalAlign: 'top',
  };

  return (
    <TableRow>
      <TableCell style={cellStyle}>{props.property + ':'}</TableCell>
      <TableCell style={cellStyle}>{props.children}</TableCell>
    </TableRow>
  );
};
