import React from 'react';
import get from 'lodash/get';
import { UseFormikType } from './useFormikType';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';

// Extend DatePicker props with formik-related properties
type FormikDatePickerProps = {
  formik: UseFormikType;
  id: string;
  label?: string;
  disableFuture?: boolean;
  disablePast?: boolean;
  disabled?: boolean;
};

// ****************************
// There is datePicker in insuranceDetailsDialog and insuranceInvoiceDialog
// ***************************
/**
 * Wrapper for MUI DatePicker integrated with Formik
 */
export default function FormikDatePicker(props: FormikDatePickerProps): JSX.Element {
  const { formik, id, label, disabled, ...other } = props;

  if (!id) throw new Error('FormikDatePicker "id" property must not be empty');

  const formikError = get(formik.errors, id);
  const formikTouched = get(formik.touched, id, false);
  const helperText = formikTouched && formikError ? String(formikError) : undefined;

  const formikValue = get(formik.values, id, null);
  const value: Dayjs | null = formikValue ? dayjs(formikValue) : null;
  return (
    <DatePicker
      label={label}
      value={value}
      onChange={(date) => {
        if (date && dayjs(date).isValid()) {
          formik.setFieldValue(id, date.toDate());
        } else {
          formik.setFieldValue(id, null);
        }
      }}
      slotProps={{
        textField: {
          id,
          error: formikTouched && Boolean(formikError),
          helperText: helperText,
          disabled: disabled,
          fullWidth: true,
          InputProps: {
            inputProps: {
              readOnly: true, // Prevent user from manually setting a value
            },
          },
        },
      }}
      views={['year', 'month', 'day']}
      {...other}
    />
  );
}
