import { useTranslation } from 'react-i18next';
import { AccountBalanceWallet, Build, DeviceHub, Group, Info, VerifiedUser } from '@mui/icons-material';
import { FaHandHoldingUsd } from 'react-icons/fa';

import { AboutPage } from './about/AboutPage';
import { NetworkPage } from './network/NetworkPage';
import { WalletPage } from './wallet/WalletPage';
import { MachinesPage } from './machines/MachinesPage';
import { PaymentsPage } from './payments/PaymentsPage';
import { MembersPage } from './members/MembersPage';
import { InsurancesPage } from './insurance/InsurancesPage';

// type IconType = typeof DeviceHub;
type IconType = React.ComponentType;

/**
 * Type definition of the routes.
 */
export interface RouteType {
  path: string;
  label: string;
  exact?: boolean;
  icon: IconType;
  component: () => JSX.Element;
}

/**
 * Hook to generate routes based on the current language.
 */
export function useAppRoutes(): {
  routeWallet: RouteType;
  routePayments: RouteType;
  routeMachines: RouteType;
  routeInsurances: RouteType;
  routeMembers: RouteType;
  routeNetwork: RouteType;
  routeAbout: RouteType;
} {
  // Translations
  const { t } = useTranslation();
  const walletTxt = t('wallet');
  const machinesTxt = t('machines');
  const membersTxt = t('members');
  const insurersTxt = t('insurers');
  const paymentsTxt = t('payments');
  const aboutTxt = t('about');
  const networkTxt = t('network');

  const routeWallet: RouteType = {
    path: '/wallet',
    label: walletTxt,
    icon: AccountBalanceWallet,
    component: WalletPage,
  };

  const routePayments: RouteType = {
    path: '/payments',
    label: paymentsTxt,
    icon: FaHandHoldingUsd,
    component: PaymentsPage,
  };

  const routeMachines: RouteType = {
    path: '/machines',
    label: machinesTxt,
    icon: Build,
    component: MachinesPage,
  };

  const routeInsurances: RouteType = {
    path: '/insurances',
    label: insurersTxt,
    icon: VerifiedUser,
    component: InsurancesPage,
  };

  const routeMembers: RouteType = {
    path: '/members',
    label: membersTxt,
    icon: Group,
    component: MembersPage,
  };

  const routeNetwork: RouteType = {
    path: '/network',
    label: networkTxt,
    icon: DeviceHub,
    component: NetworkPage,
  };

  const routeAbout: RouteType = {
    path: '/about',
    label: aboutTxt,
    icon: Info,
    component: AboutPage,
  };

  return {
    routeWallet,
    routePayments,
    routeMachines,
    routeInsurances,
    routeMembers,
    routeNetwork,
    routeAbout,
  };
}
