import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Modal, Paper, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams, GridFilterOperator } from '@mui/x-data-grid';
import ReceiptIcon from '@mui/icons-material/Receipt';

import ColorChip from '../../assets/ColorChip';
import { PaymentDto, PaymentStatusEnum } from '../../../generated';
import { NumberFormatEurText } from '../../assets/numberformat/NumberFormatEur';
import { NumberFormatUnitsText } from '../../assets/numberformat/NumberFormatUnits';
import { dateComparer, formatCordaX500Name, formatDateTimeToGermanStyle } from '../../../common/format';
import {
  CustomLocaleText,
  CustomNoRowsOverlay,
  CustomPagination,
  CustomToolbar,
} from '../../assets/x-data-grid/helpers';
import { PaymentDetails } from './PaymentDetails';
import { useStyles } from '../../Styles';
import DataGridSkeleton from '../../assets/DataGridSkeleton';

export function PaymentsTable(props: { paymentsData: PaymentDto[]; promiseInProgress: boolean }): JSX.Element {
  const isLoading = props.promiseInProgress;
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<PaymentDto>();
  const localeText = CustomLocaleText();
  const classes = useStyles();

  // Trnaslations
  const { t } = useTranslation();
  const unitTxt = t('unit');
  const closeTxt = t('closeBtn');
  const amountTxt = t('amount');
  const largerThanTxt = t('largerThan');
  const timestampTxt = t('timestamps');
  const lastUpdateTxt = t('lastUpdate');
  const machineOwnerTxt = t('machineOwner');
  const machineUserTxt = t('machineUser');
  const machineNameTxt = t('machineName');
  const detailsTxt = t('details');
  const noRecordsToDisplayTxt = t('noRecordsToDisplay');

  // Custom Filter Operators
  const dateFilterOperators: GridFilterOperator[] = [
    {
      label: 'Filter by Date',
      value: 'equals', // Customize the logic to suit your needs.
      getApplyFilterFn: (filterItem) => {
        if (!filterItem.value) return null;
        return (row: PaymentDto) => dateComparer(filterItem.value, row.created); // Example for date comparison.
      },
    },
  ];

  // Columns
  const columns: GridColDef[] = [
    {
      field: 'created',
      headerName: timestampTxt,
      type: 'date',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => formatDateTimeToGermanStyle(params.value),
      filterOperators: dateFilterOperators,
    },
    {
      field: 'machineOwner',
      headerName: machineOwnerTxt,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => formatCordaX500Name(params.value),
    },
    {
      field: 'machineUser',
      headerName: machineUserTxt,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => formatCordaX500Name(params.value),
    },
    {
      field: 'machineName',
      headerName: machineNameTxt,
      flex: 1,
    },
    {
      field: 'totalUnits',
      headerName: unitTxt,
      flex: 0.5,
      align: 'right',
      renderCell: (params: GridRenderCellParams) => <NumberFormatUnitsText value={params.value} />,
      filterOperators: [
        {
          label: largerThanTxt,
          value: 'greaterThan',
          getApplyFilterFn: (filterItem) => {
            if (!filterItem.value) return null;
            return (row: PaymentDto) => row.totalUnits > filterItem.value;
          },
        },
      ],
    },
    {
      field: 'totalAmount',
      headerName: amountTxt,
      flex: 0.5,
      align: 'right',
      renderCell: (params: GridRenderCellParams) => <NumberFormatEurText value={params.value} />,
      filterOperators: [
        {
          label: largerThanTxt,
          value: 'greaterThan',
          getApplyFilterFn: (filterItem) => {
            if (!filterItem.value) return null;
            return (row: PaymentDto) => row.totalAmount > filterItem.value;
          },
        },
      ],
    },
    {
      field: 'paymentStatus',
      headerName: 'Status',
      flex: 0.75,
      align: 'center',
      renderCell: (params: GridRenderCellParams) => <PaymentStatusChip paymentStatus={params.value} />,
    },
    {
      field: 'updated',
      headerName: lastUpdateTxt,
      type: 'date',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => formatDateTimeToGermanStyle(params.value),
      filterOperators: dateFilterOperators,
    },
    {
      field: 'details',
      headerName: detailsTxt,
      flex: 0.4,
      width: 80,
      align: 'center',
      renderCell: (cellvalue: GridRenderCellParams) => (
        <ReceiptIcon
          sx={{
            cursor: 'pointer',
            fontSize: 22,
            color: '#4caf50',
            '&:hover': {
              color: '#008CBA',
            },
          }}
          onClick={() => handleRowClick(cellvalue.row)}
        />
      ),
    },
  ];

  // Handle row click to show details in the modal
  const handleRowClick = (rowData: PaymentDto) => {
    setSelectedRow(rowData);
    setModalOpen(true);
  };

  // Close the modal
  const handleClose = () => {
    setModalOpen(false);
    setSelectedRow(undefined);
  };

  // Render Component
  return (
    <Paper elevation={0} sx={{ height: '60vh', width: '100%', mt: '0.5rem' }}>
      {props.promiseInProgress ? (
        <DataGridSkeleton />
      ) : (
        <DataGrid
          className={classes.customScrollbar}
          rows={props.paymentsData}
          columns={columns}
          getRowId={(row: PaymentDto) => row.paymentId}
          loading={props.promiseInProgress}
          localeText={localeText}
          slotProps={{
            cell: { style: { outline: 'none' } },
          }}
          slots={{
            noRowsOverlay: () => <CustomNoRowsOverlay isLoading={isLoading} />,
            toolbar: CustomToolbar,
            pagination: CustomPagination,
          }}
          pagination
          initialState={{
            sorting: {
              sortModel: [{ field: 'created', sort: 'desc' }],
            },
            pagination: { paginationModel: { pageSize: 10, page: 0 } },
          }}
          pageSizeOptions={[5, 10, 20]}
        />
      )}
      {/* Modal for showing row details */}
      <Modal open={isModalOpen} onClose={handleClose}>
        <Box
          className={classes.customScrollbar}
          sx={(theme) => ({
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: theme.palette.mode === 'dark' ? '#1C1C1C' : 'background.paper',
            boxShadow: 24,
            borderRadius: 1,
            p: 2,
          })}
        >
          {selectedRow ? <PaymentDetails payment={selectedRow} /> : <Typography>{noRecordsToDisplayTxt}</Typography>}
          <Button onClick={handleClose} sx={{ mt: 1 }} variant="contained">
            {closeTxt}
          </Button>
        </Box>
      </Modal>
    </Paper>
  );
}

// Helper Components
function PaymentStatusChip({ paymentStatus }: { paymentStatus: PaymentStatusEnum }) {
  let chipColor: 'green' | 'orange' | 'grey' = 'grey';
  let paymentStatusLabel = '';

  // Trnaslations
  const { t } = useTranslation();
  const pendingTxt = t('paymentsTable.pending');
  const initiatedTxt = t('paymentsTable.initiated');
  const confirmedTxt = t('paymentsTable.confirmed');

  switch (paymentStatus) {
    case PaymentStatusEnum.Initiated:
      chipColor = 'grey';
      paymentStatusLabel = initiatedTxt;
      break;
    case PaymentStatusEnum.Pending:
      chipColor = 'orange';
      paymentStatusLabel = pendingTxt;
      break;
    case PaymentStatusEnum.Confirmed:
      chipColor = 'green';
      paymentStatusLabel = confirmedTxt;
      break;
  }

  return <ColorChip chipColor={chipColor} size="small" label={paymentStatusLabel} />;
}
