import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { useTranslation } from 'react-i18next';
import React, { useCallback } from 'react';
import { Chip, IconButton, CircularProgress, Paper, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import { Edit } from '@mui/icons-material';

import { formatCordaX500Name, formatDateTimeToGermanStyle } from '../../../common/format';
import { MembershipDto, MembershipRoleEnum, MembershipStatusEnum } from '../../../generated';
import useNotifications from '../../assets/useNotifications';
import { getMembershipsApi } from '../../../common/keycloak';
import MemberStatusChip from './MemberStatusChip';
import { useConfig } from '../../../common/config';
import Spinner from '../../assets/Spinner';
import { CustomLocaleText, CustomPagination, CustomToolbar } from '../../assets/x-data-grid/helpers';
import { useStyles } from '../../Styles';
import DataGridSkeleton from '../../assets/DataGridSkeleton';

export function MembersTable(props: {
  memberships: MembershipDto[];
  promiseInProgress: boolean;
  setEditMembershipDialog: (open: { open: boolean; membership: MembershipDto }) => void;
  reload: () => void;
}): JSX.Element {
  const theme = useTheme();
  const { membersPage } = useConfig();
  const getRowId = useCallback((row: MembershipDto) => row.membershipId, []);
  const localeText = CustomLocaleText();
  const { dashboard } = useConfig();
  const classes = useStyles();

  // Trnaslations
  const { t } = useTranslation();
  const editTxt = t('edit');
  const rolesTxt = t('roles');
  const createdTxt = t('created');
  const insurerTxt = t('insurer');
  const updatedTxt = t('updated');
  const machineUserTxt = t('machineUser');
  const machineOwnerTxt = t('machineOwner');
  const identityTxt = t('membersTable.identity');
  const machineObserverTxt = t('machineObserver');
  const paymentProviderTxt = t('paymentProvider');

  function MembershipRoleChip(props: { membershipRole: MembershipRoleEnum }) {
    let label;
    switch (props.membershipRole) {
      case MembershipRoleEnum.Bno: {
        label = 'BNO';
        break;
      }
      case MembershipRoleEnum.MachineOwner: {
        label = machineOwnerTxt;
        break;
      }
      case MembershipRoleEnum.MachineUser: {
        label = machineUserTxt;
        break;
      }
      case MembershipRoleEnum.PaymentProvider: {
        label = paymentProviderTxt;
        break;
      }
      case MembershipRoleEnum.Insurer: {
        label = insurerTxt;
        break;
      }
      case MembershipRoleEnum.MachineObserver: {
        label = machineObserverTxt;
        break;
      }
      default: {
        label = props.membershipRole;
        break;
      }
    }

    return <Chip size="small" label={label} style={{ margin: theme.spacing(0.25) }} />;
  }

  /**
   * Define the columns
   */
  const columns: GridColDef<MembershipDto>[] = [
    {
      field: 'identity',
      headerName: identityTxt,
      flex: 0.75,
      valueGetter: (value) => {
        if (!value) {
          return 'NA';
        }
        return formatCordaX500Name(value);
      },
      sortable: true,
    },
    {
      field: 'membershipStatus',
      headerName: 'Status',
      flex: 0.25,
      renderCell: (params: GridRenderCellParams) => <MemberStatusChip membershipStatus={params.value} size="small" />,
    },
    {
      field: 'membershipRoles',
      headerName: rolesTxt,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <>
          {params.value.map((role: MembershipRoleEnum, index: number) => (
            <MembershipRoleChip key={index} membershipRole={role} />
          ))}
        </>
      ),
    },
    {
      field: 'created',
      headerName: createdTxt,
      flex: 0.5,
      valueGetter: (value) => {
        if (!value) {
          return 'NA';
        }
        return formatDateTimeToGermanStyle(value);
      },
      sortable: true,
    },
    {
      field: 'updated',
      headerName: updatedTxt,
      flex: 0.5,
      valueGetter: (value) => {
        if (!value) {
          return 'NA';
        }
        return formatDateTimeToGermanStyle(value);
      },
      sortable: true,
    },
  ];

  // Add edit button if applicable
  if (membersPage.canEditMembers) {
    columns.push({
      field: 'actions',
      headerName: editTxt,
      flex: 0.25,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <IconButton onClick={() => props.setEditMembershipDialog({ open: true, membership: params.row })}>
          <Edit />
        </IconButton>
      ),
    });
  }

  // Placeholder component for empty data
  function EmptyDataInformation() {
    if (props.promiseInProgress || props.memberships == null) return <Spinner />;

    if (membersPage.canCreateBusinessNetwork) return <CreateBusinessNetworkInformation onSuccess={props.reload} />;
    else return <RequestMembershipInformation onSuccess={props.reload} />;
  }

  // Function to make sure that only the BNO can see suspended participants
  function filterRows(rows: MembershipDto[]): MembershipDto[] {
    return rows.filter((row) => {
      const filtered =
        row.membershipStatus === MembershipStatusEnum.Suspended ||
        row.membershipStatus === MembershipStatusEnum.Pending;

      // If current user is a BNO, include all rows
      if (isBno()) {
        return true;
      }

      // If current user is not a BNO, show only ACTIVE participants
      return !filtered;
    });
  }

  function isBno(): boolean {
    return props.memberships.some((ms) => {
      return (
        ms.membershipRoles.includes(MembershipRoleEnum.Bno) &&
        ms.identity.organization.toLowerCase() === dashboard.profile.toLowerCase()
      );
    });
  }
  const filteredRows = filterRows(props.memberships || []);

  return (
    <Paper elevation={0} sx={{ height: '60vh', width: '100%', mt: '0.5rem' }}>
      {props.promiseInProgress ? (
        <DataGridSkeleton />
      ) : (
        <DataGrid
          className={classes.customScrollbar}
          rows={filteredRows}
          columns={columns}
          loading={props.promiseInProgress}
          getRowId={getRowId}
          localeText={localeText}
          slotProps={{
            cell: { style: { outline: 'none' } },
          }}
          slots={{
            noRowsOverlay: EmptyDataInformation,
            toolbar: CustomToolbar,
            pagination: CustomPagination,
          }}
          pagination
          initialState={{
            sorting: { sortModel: [{ field: 'identity', sort: 'asc' }] },
            pagination: { paginationModel: { pageSize: 10, page: 0 } },
          }}
          pageSizeOptions={[5, 10, 20]}
          filterMode="client"
          sortingMode="client"
        />
      )}
    </Paper>
  );
}

/**
 * Info that is shown to request membership
 */
function RequestMembershipInformation(props: { onSuccess: () => void }): JSX.Element {
  const { showError, showSuccess } = useNotifications();

  const { promiseInProgress } = usePromiseTracker({ area: 'membership-request', delay: 200 });

  // Trnaslations
  const { t } = useTranslation();
  const membershipRequestedSuccessTxt = t('membersTable.membershipRequestedSuccess');
  const membershipRequestedErrorTxt = t('membersTable.membershipRequestedError');
  const membershipRequestedErrorStatusTxt = t('membersTable.membershipRequestedErrorStatus');
  const networkMembershipTxt = t('membersTable.networkMembership');
  const requestMembershipTxt = t('membersTable.requestMembership');

  const handleClick = async function () {
    // Call MemberApi to request membership
    const requestMembership = async () => {
      const api = await getMembershipsApi();
      return api.requestMembership();
    };

    try {
      const result = await trackPromise(requestMembership(), 'membership-request');

      if (result) {
        showSuccess(membershipRequestedSuccessTxt);
        props.onSuccess();
      } else {
        showError(membershipRequestedErrorTxt);
      }
    } catch (e) {
      if (e instanceof Response) {
        showError(`${membershipRequestedErrorStatusTxt} "${e.status}": ${await e.text()}`);
      } else {
        showError(JSON.stringify(e));
      }
    }
  };

  return (
    <NoNetworkInformation
      onClick={handleClick}
      promiseInProgress={promiseInProgress}
      description={networkMembershipTxt}
      label={requestMembershipTxt}
    />
  );
}

/**
 * Info that is shown to create a new business network
 */
function CreateBusinessNetworkInformation(props: { onSuccess: () => void }) {
  const { showError, showSuccess } = useNotifications();

  const { promiseInProgress } = usePromiseTracker({ area: 'membership-request', delay: 200 });

  // Trnaslations
  const { t } = useTranslation();
  const networkCreateSuccessTxt = t('membersTable.networkCreateSuccess');
  const networkCreateErrorTxt = t('membersTable.networkCreateError');
  const networkCreateErrorStatusTxt = t('membersTable.networkCreateErrorStatus');
  const networkExistanceTxt = t('membersTable.networkExistance');
  const createNetworkTxt = t('membersTable.createNetwork');

  const handleClick = async function () {
    // Call MemberApi to create business network
    const createBusinessNetwork = async () => {
      const api = await getMembershipsApi();
      return api.createBusinessNetwork();
    };

    try {
      const result = await trackPromise(createBusinessNetwork(), 'membership-request');

      if (result) {
        showSuccess(networkCreateSuccessTxt);
        props.onSuccess();
      } else {
        showError(networkCreateErrorTxt);
      }
    } catch (e) {
      if (e instanceof Response) {
        showError(`${networkCreateErrorStatusTxt} "${e.status}": ${await e.text()}`);
      } else {
        showError(JSON.stringify(e));
      }
    }
  };

  return (
    <NoNetworkInformation
      onClick={handleClick}
      promiseInProgress={promiseInProgress}
      description={networkExistanceTxt}
      label={createNetworkTxt}
    />
  );
}

/**
 * Info that is shown when no business network is found
 */
function NoNetworkInformation(props: {
  onClick: () => void;
  promiseInProgress: boolean;
  label: string;
  description: string;
}) {
  return (
    <Grid container direction="column" spacing={2}>
      <Grid>
        <Typography>{props.description}</Typography>
      </Grid>
      <Grid>
        <Button variant="contained" color="primary" onClick={props.onClick} disabled={props.promiseInProgress}>
          {props.promiseInProgress && <CircularProgress size={22} color="secondary" style={{ marginRight: 10 }} />}
          {props.label}
        </Button>
      </Grid>
    </Grid>
  );
}
