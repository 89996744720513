import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Paper, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';

import { MachineActivityDto, PaymentDto, PaymentInformationDto } from '../../../generated';
import { MachineActivityTable } from '../../assets/MachineActivityTable';
import { getPaymentsApi } from '../../../common/keycloak';
import { useStyles } from '../../Styles';
import { CopyableId } from '../../assets/CopyableId';

export function PaymentDetails(props: { payment: PaymentDto }): JSX.Element {
  const classes = useStyles();

  const { promiseInProgress } = usePromiseTracker({ area: 'payment-details', delay: 200 });
  const [machineActivities, setMachineActivities] = useState([] as MachineActivityDto[]);

  // Trnaslations
  const { t } = useTranslation();
  const payerTxt = t('payer');
  const payeeTxt = t('payee');
  const machineIdTxt = t('machineId');
  const paymentIdTxt = t('paymentId');
  const includedMachineActivitiesTxt = t('includedMachineActivities');

  // Load machine activities
  useEffect(() => {
    const loadMachineActivities = async function () {
      const paymentsApi = await getPaymentsApi();
      const machineActivities = await paymentsApi.getMachineActivitiesForPayment(props.payment.paymentId);
      setMachineActivities(machineActivities);
    };

    trackPromise(loadMachineActivities(), 'payment-details');
  }, [props.payment.paymentId]);

  const PaymentInformation = function (props: {
    label: string;
    paymentInformation: PaymentInformationDto;
  }): JSX.Element {
    return (
      <>
        <Typography gutterBottom fontWeight={600} variant="subtitle2">
          {props.label}
        </Typography>
        <Typography variant="body2">{props.paymentInformation.legalName}</Typography>
        <Typography variant="body2">{props.paymentInformation.iban}</Typography>
      </>
    );
  };

  const IDInformation = function (props: { machineId: string; paymentId: string }) {
    return (
      <>
        <Typography gutterBottom variant="subtitle2" fontWeight={600}>
          IDs
        </Typography>
        <Typography variant="body2" fontWeight={500}>{`${paymentIdTxt}:`}</Typography>
        <Typography variant="body2">
          <CopyableId fullId={props.paymentId} />
        </Typography>
        <Typography variant="body2" fontWeight={500}>{`${machineIdTxt}:`}</Typography>
        <Typography variant="body2">
          <CopyableId fullId={props.machineId} />
        </Typography>
      </>
    );
  };

  return (
    <div className={classes.divTable}>
      <Grid container direction="column" spacing={1}>
        <Grid>
          <Paper className={classes.papertable}>
            <Grid container direction="row" spacing={4}>
              <Grid size={{ lg: 4, md: 6, xs: 12 }}>
                <IDInformation machineId={props.payment.machineId} paymentId={props.payment.paymentId} />
              </Grid>
              <Grid size={{ lg: 4, md: 6, xs: 12 }}>
                <PaymentInformation label={payerTxt} paymentInformation={props.payment.payerPaymentInformation} />
              </Grid>
              <Grid size={{ lg: 4, md: 6, xs: 12 }}>
                <PaymentInformation label={payeeTxt} paymentInformation={props.payment.payeePaymentInformation} />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid>
          <Paper className={classes.papertable}>
            <Typography gutterBottom variant="subtitle1">
              {includedMachineActivitiesTxt}
            </Typography>
            <MachineActivityTable
              machineActivities={machineActivities}
              promiseInProgress={promiseInProgress}
              machineId={props.payment.machineId}
              filePrefix={props.payment.machineName}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
