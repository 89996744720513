import React, { useState } from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useTranslation } from 'react-i18next';

export function CopyableId({ fullId }: { fullId: string }) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const shortenedId = `${fullId.substring(0, 7)}...${fullId.substring(fullId.length - 5)}`;

  // Translations
  const { t } = useTranslation();
  const copiedTxt = t('tableToolbar.copied');
  const clickToCopyTxt = t('tableToolbar.clickToCopy');

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(fullId);
    setTooltipOpen(true);
    setTimeout(() => setTooltipOpen(false), 2000);
  };

  return (
    <Tooltip title={tooltipOpen ? copiedTxt : clickToCopyTxt}>
      <Box
        onClick={handleCopyToClipboard}
        sx={(theme) => ({
          display: 'inline-flex',
          alignItems: 'center',
          cursor: 'pointer',
          border: `1px solid ${theme.palette.divider}`,
          padding: '1px 4px',
          borderRadius: '4px',
          mb: '4px',
        })}
      >
        <Typography
          sx={{
            color: 'primary.main',
            fontSize: '14px',
          }}
        >
          {shortenedId}
        </Typography>
        <ContentCopyIcon
          sx={{
            color: 'primary.main',
            fontSize: '14px',
            marginLeft: '8px',
          }}
        />
      </Box>
    </Tooltip>
  );
}
