import React from 'react';
import { useTranslation } from 'react-i18next';
import { ChipProps } from '@mui/material';

import { MembershipStatusEnum } from '../../../generated';
import ColorChip from '../../assets/ColorChip';

type MemberStatusChipProps = ChipProps & { membershipStatus?: MembershipStatusEnum };

/**
 * Show the membership status as chip
 * @param props the status
 */
export default function MemberStatusChip(props: MemberStatusChipProps): JSX.Element {
  const { membershipStatus, ...other } = props;

  // Trnaslations
  const { t } = useTranslation();
  const activeTxt = t('active');
  const pendingTxt = t('pending');
  const suspendedTxt = t('suspended');

  let chipColor: 'grey' | 'green' | 'orange' = 'green';
  let label = '';
  switch (membershipStatus) {
    case MembershipStatusEnum.Active: {
      chipColor = 'green';
      label = activeTxt;
      break;
    }
    case MembershipStatusEnum.Pending: {
      chipColor = 'orange';
      label = pendingTxt;
      break;
    }
    case MembershipStatusEnum.Suspended: {
      chipColor = 'grey';
      label = suspendedTxt;
      break;
    }
  }

  return <ColorChip chipColor={chipColor} label={label} {...other} />;
}
