import { trackPromise } from 'react-promise-tracker';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import AddBoxIcon from '@mui/icons-material/AddBox';
import PaymentIcon from '@mui/icons-material/Payment';
import { ListItemIcon, ListItemText, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { useConfig } from '../../common/config';
import { getMemberApi } from '../../common/keycloak';
import PaymentInformationDialog from './PaymentInformationDialog';
import { MemberDto, PaymentInformationDto } from '../../generated';
import ExtraPaymentInformationDialog from './ExtraPaymentInformationDialog';

export default function PaymentDropDown(props: { anchorEl: HTMLElement | null; onClose: () => void }): JSX.Element {
  const [paymentInformationDialogOpen, setPaymentInformationDialogOpen] = React.useState(false);
  const [ExtraPaymentInformationDialogOpen, setExtraPaymentInformationDialogOpen] = React.useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [reloadDialog, setreloadDialog] = useState(false);

  const [members, setMembers] = useState({} as MemberDto);
  const [paymentInfoForEdit, setPaymentInfoForEdit] = useState({} as PaymentInformationDto);
  const [paymentIndex, setPaymentIndex] = useState<number>(0);
  const theme = useTheme();
  const { dashboard } = useConfig();
  // Translations
  const { t } = useTranslation();
  const dropdownTitle = t('paymentDropDown.title');
  const addAccountHover = t('paymentDropDown.addAccountHover');
  const editAccountHover = t('paymentDropDown.editAccountHover');
  const defalut = t('paymentDropDown.defalut');

  // Custom styles
  const paymentIcon = {
    size: '20px',
    marginLeft: '5px',
  };

  const accountItem = {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:active': {
      backgroundColor: 'transparent',
    },
    cursor: 'default',
    display: 'flex',
    justifyContent: 'space-around',
  };

  const accountItemGrid = {
    display: 'flex',
    alignItems: 'center',
  };

  const accountTitle = {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    marginLeft: '8px',
  };

  const icon = {
    cursor: 'pointer',
  };

  const menuItem = {
    cursor: 'pointer',
    padding: '8px 0 8px 16px',
    margin: '8px 24px',
    border: `1px solid #b3b3b3`,
    // borderRadius: theme.shape.borderRadius,
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:active': {
      backgroundColor: 'transparent',
    },
    '&:last-child': {
      marginBottom: '16px',
    },
  };

  const listItemIcon = {
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
  };

  const editPaymentHandler = (paymentForEdit: PaymentInformationDto, index: number) => {
    setPaymentInfoForEdit(paymentForEdit);
    setIsEditing(true);
    setPaymentIndex(index);
    setExtraPaymentInformationDialogOpen(true);
  };

  const addPaymentHandler = (members: MemberDto) => {
    setIsEditing(false);
    if (!members.paymentInformation) {
      setPaymentInformationDialogOpen(true);
    } else {
      if (!members.extraPaymentInformation || members.extraPaymentInformation.length === 0) {
        // If extraPaymentInformation is undefined or empty, add a new empty object
        setMembers((prevMembers) => ({
          ...prevMembers,
          extraPaymentInformation: [{} as PaymentInformationDto],
        }));
      } else if (!members.extraPaymentInformation.some((info) => Object.keys(info).length === 0)) {
        // If extraPaymentInformation does not contain an empty object, add one
        setMembers((prevMembers) => ({
          ...prevMembers,
          extraPaymentInformation: [...(prevMembers.extraPaymentInformation ?? []), {} as PaymentInformationDto],
        }));
      }

      setExtraPaymentInformationDialogOpen(true);
    }
  };

  useEffect(() => {
    const loadMember = async () => {
      const memberApi = await getMemberApi();
      const memberData = await memberApi.getMember();
      setMembers(memberData);
    };

    trackPromise(loadMember(), 'payment-drop-down');
  }, [reloadDialog]);

  const dialogChangeHandler = () => {
    setreloadDialog((prevState) => !prevState);
  };

  return (
    <div>
      <Menu
        id="simple-menu"
        anchorEl={props.anchorEl}
        keepMounted
        open={Boolean(props.anchorEl)}
        onClose={props.onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MenuItem disabled={false} sx={accountItem}>
          <ListItemIcon sx={accountItemGrid}>
            <PaymentIcon sx={paymentIcon} />
            <Typography sx={accountTitle}>{dropdownTitle}</Typography>
          </ListItemIcon>
          <Tooltip title={addAccountHover}>
            <ListItemIcon
              onClick={() => addPaymentHandler(members)}
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <AddBoxIcon sx={icon} />
            </ListItemIcon>
          </Tooltip>
        </MenuItem>
        {members.paymentInformation && (
          <Tooltip title={editAccountHover}>
            <MenuItem
              key={'DefaultPaymentInfo'}
              disabled={false}
              onClick={() => setPaymentInformationDialogOpen(true)}
              sx={menuItem}
            >
              <ListItemText>{`${members.paymentInformation.displayName} (${defalut})`}</ListItemText>

              <ListItemIcon sx={listItemIcon}>
                <EditIcon sx={icon} />
              </ListItemIcon>
            </MenuItem>
          </Tooltip>
        )}
        {members.extraPaymentInformation &&
          members.extraPaymentInformation.length > 0 &&
          members.extraPaymentInformation?.map((paymentInfo, index) => {
            if (Object.keys(paymentInfo).length > 0) {
              return (
                <Tooltip key={paymentInfo.iban} title={editAccountHover}>
                  <MenuItem disabled={false} onClick={() => editPaymentHandler(paymentInfo, index)} sx={menuItem}>
                    <>
                      <ListItemText>{paymentInfo.displayName}</ListItemText>
                      <ListItemIcon sx={listItemIcon}>
                        <EditIcon sx={icon} />
                      </ListItemIcon>
                    </>
                  </MenuItem>
                </Tooltip>
              );
            }
            return null;
          })}
      </Menu>
      {dashboard.allowManagePaymentInformation && (
        <PaymentInformationDialog
          members={members}
          onChange={dialogChangeHandler}
          open={paymentInformationDialogOpen}
          onClose={() => setPaymentInformationDialogOpen(false)}
        />
      )}
      {dashboard.allowManagePaymentInformation && (
        <ExtraPaymentInformationDialog
          members={members}
          paymentInfoForEdit={paymentInfoForEdit}
          paymentIndex={paymentIndex}
          onChange={dialogChangeHandler}
          open={ExtraPaymentInformationDialogOpen}
          isEditing={isEditing}
          onClose={() => setExtraPaymentInformationDialogOpen(false)}
        />
      )}
    </div>
  );
}
