import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { LinearProgress } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import FormikForm from '../assets/formik/FormikForm';
import { getMemberApi } from '../../common/keycloak';
import { MemberDto, MemberRequest } from '../../generated';
import useNotifications from '../assets/useNotifications';
import FormikTextField from '../assets/formik/FormikTextField';
import PaymentInfoDeleteEditDialog from './PaymentInfoDeleteEditDialog';

/**
 * JSX Element that is an overlay dialog to edit the participants own payment information
 * @param props
 */
export default function PaymentInformationDialog(props: {
  open: boolean;
  onClose: () => void;
  onChange: () => void;
  members: MemberDto;
}): JSX.Element {
  const { promiseInProgress } = usePromiseTracker({ area: 'payment-information-dialog', delay: 200 });
  const { showError, showSuccess } = useNotifications();

  const [deleteAlertDialogIsOpen, setDeleteAlertDialogIsOpen] = useState<boolean>(false);
  const [isEditable, setIsEditable] = useState<boolean>(false);

  // Trnaslations
  const { t } = useTranslation();
  const closeBtn = t('closeBtn');
  const saveBtn = t('saveBtn');
  const editBtn = t('edit');
  const displayName = t('fields.displayName');
  const legalName = t('fields.legalName');
  const displayNameRequired = t('required', { field: t('fields.displayName') });
  const legalNameRequired = t('required', { field: t('fields.legalName') });
  const displayNameCharExceed = t('exceedChar', { field: t('fields.displayName') });
  const legalNameCharExceed = t('exceedChar', { field: t('fields.legalName') });
  const ibanRequired = t('required', { field: 'IBAN' });
  const bicRequired = t('required', { field: 'BIC' });
  const duplicatedIBAN = t('extraPaymentInformationDialog.duplicatedIBAN');
  const updatedSuccess = t('extraPaymentInformationDialog.updatedSuccessMsg');
  const updatePaymentError = t('extraPaymentInformationDialog.updatePaymentError');
  const updatePaymentStatusError = t('extraPaymentInformationDialog.updatePaymentStatusError');
  const ibanInvalid = t('extraPaymentInformationDialog.ibanInvalid');
  const bicInvalid = t('extraPaymentInformationDialog.bicInvalid');
  const managePayment = t('extraPaymentInformationDialog.managePayment');
  const editSepaInfo = t('extraPaymentInformationDialog.editSepaInfo');

  const closeHandler = () => {
    setIsEditable(false);
    props.onClose();
  };
  /**
   * Edit the paymentInfo vlaues the edit button is pressed
   */
  const paymentEditHandler = () => {
    setDeleteAlertDialogIsOpen(true);
  };

  const editConfirmHandler = () => {
    setIsEditable(true);
  };

  /**
   * Send updated member state via API when the save button is pressed
   */
  const handleSave = async function (member: MemberDto) {
    // Combine the IBANs and BICs in a set
    const allIbans: Set<string> = new Set<string>();

    if (member.extraPaymentInformation) {
      member.extraPaymentInformation.forEach((paymentInfo) => {
        allIbans.add(paymentInfo.iban);
      });
    }

    if (member.paymentInformation) {
      allIbans.add(member.paymentInformation.iban);
    }

    // Check the uniqueness of IBAN
    let isIbanUnique = true;

    const ibanForEdit = member.paymentInformation.iban;

    if (allIbans.has(ibanForEdit)) {
      allIbans.delete(ibanForEdit);
    }

    if (ibanForEdit && member.extraPaymentInformation) {
      const ibanExist = member.extraPaymentInformation.some((paymentInof) => paymentInof.iban === ibanForEdit);
      if (ibanExist) {
        isIbanUnique = false;
        formik.setFieldError('paymentInformation.iban', duplicatedIBAN);
      }
    }

    if (!isIbanUnique) return;

    // Map to MemberRequest
    const memberRequest: MemberRequest = {
      paymentInformation: member.paymentInformation,
      extraPaymentInformation: member.extraPaymentInformation !== undefined ? member.extraPaymentInformation : [],
    };

    // Call MemberApi to send updated member
    try {
      const updateMember = async () => {
        const memberApi = await getMemberApi();
        return await memberApi.updateMember(memberRequest);
      };

      const result = await trackPromise(updateMember(), 'payment-information-dialog');

      // Show snackbar based on result
      if (result) {
        showSuccess(`${formik.values.paymentInformation.displayName} ${updatedSuccess}`);
        props.onClose();
      } else {
        showError(updatePaymentError);
      }
    } catch (e) {
      // Show error in snackbar
      if (e instanceof Response) {
        showError(`${updatePaymentStatusError} "${e.status}": ${await e.text()}`);
      } else {
        showError(JSON.stringify(e));
      }
    }

    // Rest states

    setIsEditable(false);
    props.onChange();
  };

  /**
   * Check if IBAN is valid
   * Based on https://rosettacode.org/wiki/IBAN#JavaScript
   */
  const checkIban = function (iban: string | undefined): boolean {
    if (!iban) return false;

    if (!iban.match(/^[\dA-Z]+$/)) return false;
    const len = iban.length;
    iban = iban.substr(4) + iban.substr(0, 4);
    let s = '';
    for (let i = 0; i < len; i += 1) {
      s += parseInt(iban.charAt(i), 36);
    }
    let m = parseInt(s.substr(0, 15)) % 97;
    for (s = s.substr(15); s; s = s.substr(13)) m = parseInt(m + s.substr(0, 13)) % 97;
    return m == 1;
  };

  /**
   * Check if BIC is valid
   * Regex based on https://wiki.xmldation.com/Support/ISO20022/General_Rules/BIC
   */
  const checkBic = function (bic: string | undefined): boolean {
    if (!bic) return false;

    return bic.match(/^[A-Z]{6}[A-Z2-9][A-NP-Z0-9]([A-Z0-9]{3}){0,1}$/) != null;
  };

  const validationSchema = yup.object({
    paymentInformation: yup.object({
      displayName: yup.string().required(displayNameRequired).max(128, displayNameCharExceed),
      legalName: yup.string().required(legalNameRequired).max(128, legalNameCharExceed),
      iban: yup
        .string()
        .required(ibanRequired)
        .test('test-iban', ibanInvalid, (iban) => checkIban(iban)),
      bic: yup
        .string()
        .required(bicRequired)
        .test('test-bic', bicInvalid, (bic) => checkBic(bic)),
    }),
  });

  const formik = useFormik({
    initialValues: {} as MemberDto,
    validationSchema: validationSchema,
    onSubmit: handleSave,
  });

  // Load data via memberAPI call
  useEffect(
    () => {
      if (!props.open) return;
      formik.resetForm();

      formik.setValues(props.members);
    },

    // Adding 'formik' to dependencies creates an infinite loop as formik changes every render
    [props.open],
  ); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <Dialog open={props.open} onClose={closeHandler} aria-labelledby="form-dialog-title">
        {promiseInProgress && <LinearProgress />}
        <DialogTitle id="form-dialog-title">{managePayment}</DialogTitle>
        <FormikForm formik={formik}>
          <DialogContent>
            <DialogContentText>{editSepaInfo}</DialogContentText>
            <FormikTextField
              formik={formik}
              id="paymentInformation.displayName"
              variant="outlined"
              margin="normal"
              fullWidth
              label={displayName}
              required
              disabled={promiseInProgress || !isEditable}
            />
            <FormikTextField
              formik={formik}
              id="paymentInformation.legalName"
              variant="outlined"
              margin="normal"
              fullWidth
              label={legalName}
              required
              disabled={promiseInProgress || !isEditable}
            />
            <FormikTextField
              formik={formik}
              id="paymentInformation.iban"
              variant="outlined"
              margin="normal"
              fullWidth
              label="IBAN"
              required
              disabled={promiseInProgress || !isEditable}
            />
            <FormikTextField
              formik={formik}
              id="paymentInformation.bic"
              variant="outlined"
              margin="normal"
              fullWidth
              label="BIC"
              required
              disabled={promiseInProgress || !isEditable}
            />
          </DialogContent>
          <DialogActions>
            {isEditable ? (
              <Button variant="contained" color="primary" type="submit" disabled={promiseInProgress || !formik.isValid}>
                {saveBtn}
              </Button>
            ) : (
              <Button variant="contained" color="primary" onClick={paymentEditHandler}>
                {editBtn}
              </Button>
            )}
            <Button variant="contained" onClick={closeHandler}>
              {closeBtn}
            </Button>
          </DialogActions>
        </FormikForm>
      </Dialog>
      {deleteAlertDialogIsOpen && (
        <PaymentInfoDeleteEditDialog
          open={deleteAlertDialogIsOpen}
          onClose={() => setDeleteAlertDialogIsOpen(false)}
          onConfirm={editConfirmHandler}
          setIsEditable={setIsEditable}
        />
      )}
    </div>
  );
}
