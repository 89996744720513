import React from 'react';
import { Skeleton, Box, Paper } from '@mui/material';

const DataGridSkeleton = (): JSX.Element => {
  return (
    <Paper elevation={0} sx={{ height: '60vh', width: '100%', mt: '0.5rem', padding: '1rem' }}>
      <Box display="flex" flexDirection="column" gap={2}>
        {/* Toolbar Skeleton */}
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Skeleton variant="text" width="30%" height={30} />
          <Skeleton variant="rectangular" width={100} height={30} />
        </Box>

        {/* Table Header Skeleton */}
        <Skeleton variant="rectangular" width="100%" height={40} />

        {/* Table Rows Skeleton */}
        {[...Array(5)].map((_, index) => (
          <Skeleton key={index} variant="rectangular" width="100%" height={40} />
        ))}
      </Box>
    </Paper>
  );
};

export default DataGridSkeleton;
