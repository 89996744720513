import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, List, ListItem, ListItemText, Paper, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';

import { GetMachineActivitiesActivityStatusEnum, MachineActivityDto, MachineDto } from '../../../generated';
import { formatCordaX500Name, formatDateTimeToGermanStyle } from '../../../common/format';
import { MachineActivityTable } from '../../assets/MachineActivityTable';
import { DetailsBlock } from '../../assets/details/DetailsBlock';
import { DetailsEntry } from '../../assets/details/DetailsEntry';
import { getMachinesApi } from '../../../common/keycloak';
import { useStyles } from '../../Styles';
import { CopyableId } from '../../assets/CopyableId';

export function MachineDetails(props: { machine: MachineDto }) {
  const classes = useStyles();

  const { promiseInProgress } = usePromiseTracker({ area: 'machine-details', delay: 200 });
  const [machineActivities, setMachineActivities] = useState([] as MachineActivityDto[]);

  // Trnaslations
  const { t } = useTranslation();
  const updatedTxt = t('updated');
  const createdTxt = t('created');
  const noObserverTxt = t('noObserver');
  const machineIdTxt = t('machineId');
  const timestampTxt = t('timestamp');
  const machineDetailsTxt = t('machineDetails.machineDetails');
  const machineObserversTxt = t('machineObservers');
  const machineActivitiesTxt = t('machineDetails.machineActivities');

  // Load machine activities
  useEffect(() => {
    const loadMachineActivities = async function () {
      const machinesApi = await getMachinesApi();
      const machineActivities = await machinesApi.getMachineActivities(
        props.machine.machineId,
        GetMachineActivitiesActivityStatusEnum.Unpaid,
      );
      setMachineActivities(machineActivities);
    };

    trackPromise(loadMachineActivities(), 'machine-details');
  }, [props.machine.machineId]);

  return (
    <div className={classes.divTable}>
      <Grid container direction="column" spacing={1}>
        <Grid>
          <Paper className={classes.papertable}>
            <Grid container direction="row" spacing={4}>
              <Grid size={{ md: 6, xs: 12 }}>
                <DetailsBlock title={machineDetailsTxt}>
                  <DetailsEntry property={machineIdTxt}>
                    <CopyableId fullId={props.machine.machineId} />
                  </DetailsEntry>
                  <DetailsEntry property={machineObserversTxt}>
                    <List dense disablePadding>
                      {props.machine.machineObservers.length > 0 ? (
                        props.machine.machineObservers.map((observer, index) => {
                          return (
                            <ListItem dense disableGutters key={index + 10000} sx={{ pt: '0px' }}>
                              <ListItemText sx={{ mt: '0px' }}>{formatCordaX500Name(observer)}</ListItemText>
                            </ListItem>
                          );
                        })
                      ) : (
                        <ListItem dense disableGutters sx={{ pt: '0px' }}>
                          <ListItemText sx={{ mt: '0px' }}>{noObserverTxt}</ListItemText>
                        </ListItem>
                      )}
                    </List>
                  </DetailsEntry>
                </DetailsBlock>
              </Grid>
              <Grid size={{ md: 6, xs: 12 }}>
                <DetailsBlock title={timestampTxt}>
                  <DetailsEntry property={createdTxt}>
                    {formatDateTimeToGermanStyle(props.machine.created)}
                  </DetailsEntry>
                  <DetailsEntry property={updatedTxt}>
                    {formatDateTimeToGermanStyle(props.machine.updated)}
                  </DetailsEntry>
                </DetailsBlock>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid>
          <Paper className={classes.papertable}>
            <Typography gutterBottom variant="subtitle1">
              {machineActivitiesTxt}
            </Typography>
            <Box sx={{ height: '320px', width: '700px' }}>
              <MachineActivityTable
                machineActivities={machineActivities}
                promiseInProgress={promiseInProgress}
                machineId={props.machine.machineId}
                filePrefix={props.machine.machineName}
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
