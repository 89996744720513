import { useTranslation } from 'react-i18next';
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

const PaymentInfoDeleteEditDialog = (props: {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isDeleteable?: boolean;
  setIsDeleteable?: (value: boolean) => void;
  setIsEditable: (value: boolean) => void;
  isEditing?: boolean;
}): JSX.Element => {
  const { t } = useTranslation();

  const confirmHandler = () => {
    props.onClose();
    props.onConfirm();
  };

  const cancelHandler = () => {
    if (props.setIsDeleteable) {
      props.setIsDeleteable(false);
    }
    if (props.isEditing) {
      props.setIsEditable(false);
    }
    props.onClose();
  };

  return (
    <React.Fragment>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t('paymentInfoDeleteEditDialog.warning')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.isDeleteable
              ? t('paymentInfoDeleteEditDialog.descriptionDelete')
              : t('paymentInfoDeleteEditDialog.descriptionEdit')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelHandler}>{t('cancel')}</Button>
          <Button onClick={confirmHandler} autoFocus>
            {t('ok')}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default PaymentInfoDeleteEditDialog;
