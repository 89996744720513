import React from 'react';
import get from 'lodash/get';
import { UseFormikType } from './useFormikType';

import { TextField } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField/TextField';

// Extend <TextField> property type with "formik"
type FormikTextFieldProps = TextFieldProps & { formik: UseFormikType };

/**
 * Wrapper of <TextField> to set value, onChange, error, helperText based on values managed by formik.
 * @param props default <TextField> properties extended with "formik" parameter
 */
export default function FormikTextField(props: FormikTextFieldProps): JSX.Element {
  const { formik, id, ...other } = props;

  if (!id) throw new Error('FormikTextField "id" property must not be empty');
  const formikError = get(formik.errors, id);
  const formikTouched = get(formik.touched, id, false);
  const helperText = formikTouched && formikError ? String(formikError) : undefined;

  // Function to handle input click
  const handleClick = () => {
    const fieldValue = get(formik.values, id, '');
    if (fieldValue === 0) {
      formik.setFieldValue(id, '');
      formik.handleBlur(id);
    }
  };

  return (
    <TextField
      id={id}
      value={get(formik.values, id, '')}
      onChange={formik.handleChange}
      onClick={handleClick}
      onBlur={formik.handleBlur}
      error={formikTouched && Boolean(formikError)}
      helperText={helperText}
      {...other}
    />
  );
}
