import { RouteType, useAppRoutes } from '../components/pages/routes';
import ExternalConfig from './ExternalConfig';

export function useConfig(): {
  routes: RouteType[];
  homeRoute: RouteType | null;
  dashboard: {
    profile: string;
    title: string;
    allowManagePaymentInformation: boolean;
  };
  walletPage: { showOverviewBox: boolean };
  machinesPage: { canEditMachines: boolean };
  insurancePage: { canEditInsurances: boolean };
  membersPage: { canEditMembers: boolean; canCreateBusinessNetwork: boolean };
} {
  const { routeAbout, routeInsurances, routeMachines, routeMembers, routeNetwork, routePayments, routeWallet } =
    useAppRoutes();

  // Default config that can be overwritten based on the profile
  const ConfigDefault = {
    routes: [] as RouteType[],
    homeRoute: null as unknown as RouteType,
    dashboard: {
      profile: ExternalConfig.REACT_APP_PROFILE,
      title: ExternalConfig.REACT_APP_TITLE,
      allowManagePaymentInformation: false,
    },
    walletPage: {
      showOverviewBox: false,
    },
    machinesPage: {
      canEditMachines: false,
    },
    insurancePage: {
      canEditInsurances: false,
    },
    membersPage: {
      canEditMembers: false,
      canCreateBusinessNetwork: false,
    },
  };

  const UserConfig = { ...ConfigDefault };

  // Apply different configurations based on REACT_APP_PROFILE
  switch (ExternalConfig.REACT_APP_PROFILE) {
    case 'MACHINE_USER': {
      UserConfig.routes = [routeWallet, routePayments, routeMachines, routeInsurances, routeMembers, routeAbout];
      UserConfig.homeRoute = routeWallet;
      UserConfig.dashboard.allowManagePaymentInformation = true;
      UserConfig.walletPage.showOverviewBox = true;
      break;
    }

    case 'MACHINE_OWNER': {
      UserConfig.routes = [routeWallet, routePayments, routeMachines, routeInsurances, routeMembers, routeAbout];
      UserConfig.homeRoute = routeWallet;
      UserConfig.dashboard.allowManagePaymentInformation = true;
      UserConfig.walletPage.showOverviewBox = true;
      UserConfig.machinesPage.canEditMachines = true;
      break;
    }

    case 'PAYPERCHAIN': {
      UserConfig.routes = [
        routeWallet,
        routePayments,
        routeMachines,
        routeInsurances,
        routeMembers,
        routeNetwork,
        routeAbout,
      ];
      UserConfig.homeRoute = routeWallet;
      UserConfig.dashboard.allowManagePaymentInformation = true;
      UserConfig.walletPage.showOverviewBox = true;
      UserConfig.machinesPage.canEditMachines = true;
      UserConfig.membersPage.canEditMembers = true;
      UserConfig.membersPage.canCreateBusinessNetwork = true;
      break;
    }

    case 'INSURER': {
      UserConfig.routes = [routeInsurances, routeMachines, routeMembers, routeAbout];
      UserConfig.homeRoute = routeInsurances;
      UserConfig.insurancePage.canEditInsurances = true;
      break;
    }

    case 'MACHINE_OBSERVER': {
      UserConfig.routes = [routeMachines, routeMembers, routeAbout];
      UserConfig.homeRoute = routeMachines;
      break;
    }

    case 'PAYMENT_PROVIDER': {
      UserConfig.routes = [routeWallet, routePayments, routeMachines, routeMembers, routeAbout];
      UserConfig.homeRoute = routeWallet;
      break;
    }

    default: {
      throw new Error(
        'REACT_APP_PROFILE must be one of the following: PAYMENT_PROVIDER, MACHINE_OBSERVER, MACHINE_OWNER, MACHINE_USER, PAYPERCHAIN, INSURER',
      );
    }
  }
  const Config = UserConfig;
  return Config;
}
