import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Typography,
} from '@mui/material';
import { Edit } from '@mui/icons-material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import Spinner from '../../../assets/Spinner';
import { NumberFormatEurText } from '../../../assets/numberformat/NumberFormatEur';
import InsuranceInvoiceStatusChip from './InsuranceInvoiceStatusChip';
import { InsuranceInvoiceDto } from '../../../../generated';
import { InsuranceInvoiceDetails } from './InsuranceInvoiceDetails';
import { useConfig } from '../../../../common/config';
import { useStyles } from '../../../Styles';
import { formatDateToGermanStyle } from '../../../../common/format';

export function InsuranceInvoicesTable(props: {
  insurancesData: InsuranceInvoiceDto[];
  promiseInProgress: boolean;
  setEditInsuranceInvoiceDialog: (open: { open: boolean; insuranceInvoice: InsuranceInvoiceDto }) => void;
}): JSX.Element {
  const { insurancesData, promiseInProgress, setEditInsuranceInvoiceDialog } = props;
  const { insurancePage } = useConfig();
  const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set());
  const classes = useStyles();

  // Trnaslations
  const { t } = useTranslation();
  const editTxt = t('edit');
  const ppuAmountTxt = t('insurance.ppuAmount');
  const periodEndTxt = t('insurance.periodEnd');
  const baseAmountTxt = t('insurance.baseAmount');
  const periodStartTxt = t('insurance.periodStart');
  const invoiceAmountTxt = t('insurance.invoiceAmount');
  const noRecordsToDisplayTxt = t('noRecordsToDisplay');

  const handleExpandClick = (invoiceId: string) => {
    const newExpandedRows = new Set(expandedRows);
    if (newExpandedRows.has(invoiceId)) {
      newExpandedRows.delete(invoiceId);
    } else {
      newExpandedRows.add(invoiceId);
    }
    setExpandedRows(newExpandedRows);
  };

  const handleEdit = (insuranceInvoice: InsuranceInvoiceDto) => {
    setEditInsuranceInvoiceDialog({ open: true, insuranceInvoice });
  };

  return (
    <Paper className={classes.papertable} sx={{ height: '60vh', width: '60vw', mt: '0.5rem' }}>
      {promiseInProgress ? (
        <Spinner />
      ) : (
        <TableContainer
          className={classes.customScrollbarForMuiTable}
          component={Paper}
          sx={{
            height: '60vh',
            mb: '1rem',
            overflowY: 'auto',
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }} align="center"></TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>{periodStartTxt}</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>{periodEndTxt}</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }} align="right">
                  {baseAmountTxt}
                </TableCell>
                <TableCell sx={{ fontWeight: 'bold' }} align="right">
                  {ppuAmountTxt}
                </TableCell>
                <TableCell sx={{ fontWeight: 'bold' }} align="right">
                  {invoiceAmountTxt}
                </TableCell>
                <TableCell sx={{ fontWeight: 'bold' }} align="center">
                  Status
                </TableCell>
                {insurancePage.canEditInsurances && <TableCell align="center">{editTxt}</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {insurancesData.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    <Typography variant="body2">{noRecordsToDisplayTxt}</Typography>
                  </TableCell>
                </TableRow>
              ) : (
                insurancesData.map((row) => (
                  <>
                    <TableRow key={row.invoiceId}>
                      <TableCell align="center">
                        <IconButton onClick={() => handleExpandClick(row.invoiceId)}>
                          {expandedRows.has(row.invoiceId) ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                        </IconButton>
                      </TableCell>
                      <TableCell>{formatDateToGermanStyle(row.invoiceDetails.periodStart)}</TableCell>
                      <TableCell>{formatDateToGermanStyle(row.invoiceDetails.periodEnd)}</TableCell>
                      <TableCell align="right">
                        <NumberFormatEurText value={row.invoiceDetails.baseAmount} />
                      </TableCell>
                      <TableCell align="right">
                        <NumberFormatEurText value={row.invoiceDetails.payPerUseAmount} />
                      </TableCell>
                      <TableCell align="right">
                        <NumberFormatEurText value={row.invoiceDetails.invoiceAmount} />
                      </TableCell>
                      <TableCell align="center">
                        <InsuranceInvoiceStatusChip insuranceInvoiceStatus={row.invoiceStatus} size="small" />
                      </TableCell>
                      {insurancePage.canEditInsurances && (
                        <TableCell align="center">
                          <IconButton onClick={() => handleEdit(row)}>
                            <Edit />
                          </IconButton>
                        </TableCell>
                      )}
                    </TableRow>
                    {expandedRows.has(row.invoiceId) && (
                      <TableRow>
                        <TableCell colSpan={8}>
                          <InsuranceInvoiceDetails insuranceInvoice={row} />
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Paper>
  );
}
