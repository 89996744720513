import React from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { format } from 'date-fns';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { Box, Button, Paper, Tooltip } from '@mui/material';
import { NumberFormatEurText } from './numberformat/NumberFormatEur';
import { NumberFormatUnitsText } from './numberformat/NumberFormatUnits';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

import { MachineActivityDto, MachineActivityStatusEnum } from '../../generated';
import { formatDateTimeToGermanStyle } from '../../common/format';
import ColorChip from './ColorChip';
import { getMachinesApi } from '../../common/keycloak';
import useNotifications from './useNotifications';
import { useStyles } from '../Styles';
import { CustomNoRowsOverlay, CustomPagination, CustomLocaleText, CustomToolbar } from './x-data-grid/helpers';
import Spinner from './Spinner';

export function MachineActivityTable(props: {
  machineActivities: MachineActivityDto[];
  promiseInProgress: boolean;
  machineId?: string;
  filePrefix?: string;
}): JSX.Element {
  const isLoading = props.promiseInProgress;
  const { showInfo } = useNotifications();
  const classes = useStyles();
  const localeText = CustomLocaleText();

  const rowIdMap = new Map<MachineActivityDto, string>();

  const getRowId = (row: MachineActivityDto) => {
    if (!rowIdMap.has(row)) {
      rowIdMap.set(row, uuidv4());
    }
    return rowIdMap.get(row) as string;
  };

  // Translations
  const { t } = useTranslation();
  const paidTxt = t('paid');
  const unpaidTxt = t('unpaid');
  const pricePerUnitTxt = t('pricePerUnit');
  const exportTxt = t('tableToolbar.export');
  const exportTooltipTxt = t('tableToolbar.exportTooltip');
  const downloadFailedTxt = t('machineDetails.downloadFailed');
  const downloadSuccessTxt = t('machineDetails.downloadSuccess');
  const activityCostTxt = t('machineActivityTable.activityCost');
  const activityNoteTxt = t('machineActivityTable.activityNote');
  const activityUnitsTxt = t('machineActivityTable.activityUnits');
  const activityStatusTxt = t('machineActivityTable.activityStatus');
  const activityTimestampTxt = t('machineActivityTable.activityTimestamp');

  // Define the columns for the data grid
  const columns: GridColDef[] = [
    {
      field: 'activityTimestamp',
      headerName: activityTimestampTxt,
      flex: 1,
      type: 'dateTime',
      renderCell: (params: GridRenderCellParams) => formatDateTimeToGermanStyle(params.row.activityTimestamp),
      sortable: true,
    },
    {
      field: 'activityUnits',
      headerName: activityUnitsTxt,
      flex: 1,
      align: 'right',
      renderCell: (params: GridRenderCellParams) => <NumberFormatUnitsText value={params.row.activityUnits} />,
      sortable: true,
    },
    {
      field: 'pricePerUnit',
      headerName: pricePerUnitTxt,
      flex: 1,
      align: 'right',
      renderCell: (params: GridRenderCellParams) => <NumberFormatEurText value={params.row.pricePerUnit} />,
      sortable: true,
    },
    {
      field: 'activityCost',
      headerName: activityCostTxt,
      flex: 1,
      align: 'right',
      renderCell: (params: GridRenderCellParams) => <NumberFormatEurText value={params.row.activityCost} />,
      sortable: true,
    },
    {
      field: 'activityStatus',
      headerName: activityStatusTxt,
      flex: 1,
      align: 'center',
      renderCell: (params: GridRenderCellParams) => {
        return <MachineActivityStatusChip activityStatus={params.row.activityStatus} />;
      },
      sortable: true,
    },
    {
      field: 'activityNote',
      headerName: activityNoteTxt,
      flex: 1,
      filterable: true,
      sortable: true,
    },
  ];

  // Shows a colorful badge for each status for each activity
  function MachineActivityStatusChip(props: { activityStatus: MachineActivityStatusEnum }) {
    let chipColor: 'green' | 'orange' = 'green';
    let activityStatusLabel = '';

    switch (props.activityStatus) {
      case MachineActivityStatusEnum.Paid: {
        chipColor = 'green';
        activityStatusLabel = paidTxt;
        break;
      }
      case MachineActivityStatusEnum.Unpaid: {
        chipColor = 'orange';
        activityStatusLabel = unpaidTxt;
        break;
      }
    }

    return <ColorChip chipColor={chipColor} size="small" label={activityStatusLabel} />;
  }

  function handleDownloadMachineActivity() {
    if (props.machineId?.trim() && props.filePrefix?.trim()) {
      downloadMachineActivity(props.machineId, props.filePrefix);
    }
  }

  // Function to download machine activities CSV
  async function downloadMachineActivity(machineId: string, filePrefix: string) {
    const api = await getMachinesApi();
    try {
      const csvData = await api.getMachineActivitiesCsv(machineId);

      const blob = new Blob([csvData], { type: 'text/csv' });
      const objectUrl = window.URL.createObjectURL(blob);

      // Create anchor element to initialize download
      const anchor = document.createElement('a');
      anchor.href = objectUrl;
      anchor.download = filePrefix + '_' + format(new Date(), 'yyyyMMdd_HHmmss') + '.csv';
      anchor.click();

      // Free object url
      window.URL.revokeObjectURL(objectUrl);

      showInfo(downloadSuccessTxt);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (_) {
      showInfo(downloadFailedTxt);
    }
  }

  // Custom toolbar for the data grid
  function EnhancedToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <Box sx={{ flexGrow: 1 }} />
        <Tooltip title={exportTooltipTxt}>
          <Button
            variant="text"
            size="small"
            startIcon={<FileDownloadOutlinedIcon />}
            onClick={handleDownloadMachineActivity}
          >
            {exportTxt}
          </Button>
        </Tooltip>
      </GridToolbarContainer>
    );
  }

  // Returns main component
  return (
    <Paper elevation={0} sx={{ height: '300px', width: '700px' }}>
      {isLoading ? (
        <Spinner />
      ) : (
        <DataGrid
          className={classes.customScrollbar}
          rows={props.machineActivities}
          columns={columns}
          getRowId={getRowId}
          loading={props.promiseInProgress}
          localeText={localeText}
          slotProps={{
            cell: { style: { outline: 'none' } },
          }}
          slots={{
            noRowsOverlay: () => <CustomNoRowsOverlay isLoading={isLoading} />,
            toolbar: props.machineId ? EnhancedToolbar : CustomToolbar,
            pagination: CustomPagination,
          }}
          pagination
          initialState={{
            sorting: { sortModel: [{ field: 'machineName', sort: 'asc' }] },
            pagination: { paginationModel: { pageSize: 10, page: 0 } },
          }}
          pageSizeOptions={[5, 10, 20]}
        />
      )}
    </Paper>
  );
}
