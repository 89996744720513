import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import GavelIcon from '@mui/icons-material/Gavel';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import { CircularProgress } from '@mui/material';
import Typography from '@mui/material/Typography';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { NetworkNodesDto, NodeDto, ResponseError } from '../../../generated';
import useNotifications from '../../assets/useNotifications';
import { getNetworkApi } from '../../../common/keycloak';
import { Title } from '../../assets/Title';
import { useStyles } from '../../Styles';

/**
 * This component defines the Network component.
 */
export function NetworkPage(): JSX.Element {
  const classes = useStyles();

  const [networkInformation, setNetworkInformation] = React.useState({} as NetworkNodesDto);
  const { promiseInProgress } = usePromiseTracker({ area: 'network', delay: 200 });

  const { showError } = useNotifications();

  // Trnaslations
  const { t } = useTranslation();
  const organizationTxt = t('networkPage.organization');
  const networkInfoTxt = t('networkPage.networkInfo');
  const errorFetchingDataTxt = t('errorFetchingData');
  const activeNodeTxt = t('networkPage.activeNode');
  const userUnauthorizedTxt = t('userUnauthorized');
  const otherNodesTxt = t('networkPage.otherNodes');
  const localityTxt = t('networkPage.locality');
  const countryTxt = t('networkPage.country');
  const notaryTxt = t('networkPage.notary');

  /** fetch data*/
  useEffect(() => {
    const loadNetworkData = async () => {
      const api = await getNetworkApi();
      try {
        const result = await api.getNetworkNodes();
        setNetworkInformation(result);
      } catch (error) {
        if (error instanceof ResponseError) {
          if (error.response && error.response.status === 401) {
            showError(userUnauthorizedTxt);
          } else {
            showError(error.message);
          }
        } else {
          showError(errorFetchingDataTxt);
        }
      }
    };
    trackPromise(loadNetworkData(), 'network');
  }, [showError]);

  const renderRow = (row: 'currentUser' | 'notary') => {
    if (Object.entries(networkInformation).length !== 0) {
      const node =
        row === 'currentUser'
          ? networkInformation.activeNode
          : networkInformation.notaries && networkInformation.notaries[0];
      return (
        <TableRow>
          <TableCell>
            <Box fontWeight="fontWeightBold" m={0}>
              {row === 'currentUser' ? activeNodeTxt : notaryTxt}:
            </Box>
          </TableCell>
          <TableCell>
            {row === 'currentUser' ? <AccountCircleIcon color="primary" /> : <GavelIcon color="primary" />}
          </TableCell>
          <TableCell>{node?.identity?.organization}</TableCell>
          <TableCell>{node?.identity?.locality}</TableCell>
          <TableCell>{node?.identity?.country}</TableCell>
        </TableRow>
      );
    }
  };

  const renderPeers = () => {
    if (Object.entries(networkInformation).length !== 0) {
      return networkInformation.otherNodes?.map((element: NodeDto, index: number) => (
        <TableRow key={'rowindex' + index}>
          <TableCell>
            {index === 0 ? (
              <Box fontWeight="fontWeightBold" m={0}>
                {otherNodesTxt}
              </Box>
            ) : (
              ''
            )}
          </TableCell>
          <TableCell>{index === 0 ? <AccountTreeIcon color="primary" /> : ''}</TableCell>
          <TableCell>{element?.identity?.organization}</TableCell>
          <TableCell>{element?.identity?.locality}</TableCell>
          <TableCell>{element?.identity?.country}</TableCell>
        </TableRow>
      ));
    }
  };

  return (
    <React.Fragment>
      <Title>{networkInfoTxt}</Title>
      <br />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell />
            <TableCell>{organizationTxt}</TableCell>
            <TableCell>{localityTxt}</TableCell>
            <TableCell>{countryTxt}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {renderRow('currentUser')}
          {renderPeers()}
          {renderRow('notary')}
        </TableBody>
      </Table>
      {promiseInProgress && (
        <div className={classes.spinner}>
          <CircularProgress />
        </div>
      )}
      <div className={classes.seeMore}>
        <Typography component="h1" color="inherit" noWrap>
          Corda Version: 4.8
        </Typography>
      </div>
    </React.Fragment>
  );
}
