import { useTranslation } from 'react-i18next';
import React, { useCallback, useState } from 'react';
import { Edit } from '@mui/icons-material';
import { DataGrid, GridColDef, GridRenderCellParams, GridActionsCellItem } from '@mui/x-data-grid';
import { Box, Button, Modal, Paper, Typography } from '@mui/material';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';

import { useConfig } from '../../../common/config';
import { InsuranceDto } from '../../../generated';
import InsuranceStatusChip from './InsuranceStatusChip';
import { InsuranceDialogState } from './InsuranceDialog';
import { dateComparer, formatCordaX500Name, formatDateTimeToGermanStyle } from '../../../common/format';
import {
  CustomLocaleText,
  CustomNoRowsOverlay,
  CustomPagination,
  CustomToolbar,
} from '../../assets/x-data-grid/helpers';
import { InsuranceDetails } from './InsuranceDetails';
import { InsuranceInvoices } from './invoices/InsuranceInvoices';
import { useStyles } from '../../Styles';
import DataGridSkeleton from '../../assets/DataGridSkeleton';

export function InsurancesTable(props: {
  insurancesData: InsuranceDto[];
  promiseInProgress: boolean;
  setEditInsuranceDialog: (open: { open: InsuranceDialogState; insurance: InsuranceDto }) => void;
}): JSX.Element {
  const { insurancePage } = useConfig();
  const isLoading = props.promiseInProgress;
  const [selectedRow, setSelectedRow] = useState<InsuranceDto>();
  const [insuranceDetailsOpen, setInsuranceDetailsOpen] = useState(false);
  const [insuranceInvoiceOpen, setInsuranceInvoiceOpen] = useState(false);
  const getRowId = useCallback((row: InsuranceDto) => row.machineId, []);
  const localeText = CustomLocaleText();
  const classes = useStyles();

  // Trnaslations
  const { t } = useTranslation();
  const editTxt = t('edit');
  const closeTxt = t('closeBtn');
  const detailsTxt = t('details');
  const createdTxt = t('created');
  const invoiceTxt = t('invoice');
  const updatedTxt = t('updated');
  const insurerTxt = t('insurer');
  const selectDateTxt = t('selectDate');
  const machineNameTxt = t('machineName');
  const noRecordsToDisplayTxt = t('noRecordsToDisplay');
  const counterPartyTxt = t('insurance.counterParty');
  const insuranceNumberTxt = t('insurance.insuranceNumber');

  // Columns Configuration
  const columns: GridColDef[] = [
    {
      field: 'insuranceExternalId',
      headerName: insuranceNumberTxt,
      flex: 1,
    },
    {
      field: 'machineName',
      headerName: machineNameTxt,
      flex: 1,
    },
    {
      field: 'insurer',
      headerName: insurerTxt,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => formatCordaX500Name(params.value),
    },
    {
      field: 'counterparty',
      headerName: counterPartyTxt,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => formatCordaX500Name(params.value),
    },
    {
      field: 'insuranceStatus',
      headerName: 'Status',
      flex: 0.75,
      align: 'center',
      renderCell: (params: GridRenderCellParams) => <InsuranceStatusChip insuranceStatus={params.value} size="small" />,
    },
    {
      field: 'created',
      headerName: createdTxt,
      flex: 1,
      type: 'date',
      renderCell: (params: GridRenderCellParams) => formatDateTimeToGermanStyle(params.value),
      filterOperators: [
        {
          label: selectDateTxt,
          value: 'equals',
          getApplyFilterFn: (filterItem) => {
            if (!filterItem.value) return null;
            return (row: InsuranceDto) => dateComparer(filterItem.value, row.created);
          },
        },
      ],
    },
    {
      field: 'updated',
      headerName: updatedTxt,
      flex: 1,
      type: 'date',
      renderCell: (params: GridRenderCellParams) => formatDateTimeToGermanStyle(params.value),
      filterOperators: [
        {
          label: selectDateTxt,
          value: 'equals',
          getApplyFilterFn: (filterItem) => {
            if (!filterItem.value) return null;
            return (row: InsuranceDto) => dateComparer(filterItem.value, row.updated);
          },
        },
      ],
    },
    {
      field: 'details',
      headerName: detailsTxt,
      flex: 0.4,
      width: 80,
      align: 'center',
      renderCell: (cellvalue: GridRenderCellParams) => (
        <ReceiptIcon
          sx={{
            cursor: 'pointer',
            fontSize: 22,
            color: '#4caf50',
            '&:hover': {
              color: '#008CBA',
            },
          }}
          onClick={() => handleRowDetailsClick(cellvalue.row)}
        />
      ),
    },
    {
      field: 'insuranceInvoice',
      headerName: invoiceTxt,
      flex: 0.4,
      width: 80,
      align: 'center',
      renderCell: (cellvalue: GridRenderCellParams) => (
        <ReceiptLongIcon
          sx={{
            cursor: 'pointer',
            fontSize: 22,
            color: '#4caf50',
            '&:hover': {
              color: '#008CBA',
            },
          }}
          onClick={() => handleRowInvoiceClick(cellvalue.row)}
        />
      ),
    },
  ];

  // Add Edit Column if Editing is Allowed
  if (insurancePage.canEditInsurances) {
    columns.push({
      field: 'actions',
      type: 'actions',
      headerName: editTxt,
      flex: 0.5,
      getActions: (params) => [
        <GridActionsCellItem
          key={`edit-${params.row.insuranceExternalId}`}
          icon={<Edit sx={{ mb: '10px' }} />}
          label="Edit"
          onClick={() =>
            props.setEditInsuranceDialog({
              open: InsuranceDialogState.InsuranceBasicDialog,
              insurance: params.row,
            })
          }
        />,
      ],
    });
  }

  // Handle row click to show insurance details in the modal
  const handleRowDetailsClick = (rowData: InsuranceDto) => {
    setSelectedRow(rowData);
    setInsuranceDetailsOpen(true);
  };

  // Handle row click to show insurance invoice in the modal
  const handleRowInvoiceClick = (rowData: InsuranceDto) => {
    setSelectedRow(rowData);
    setInsuranceInvoiceOpen(true);
  };

  // Close the modal
  const handleClose = () => {
    setInsuranceDetailsOpen(false);
    setInsuranceInvoiceOpen(false);
    setSelectedRow(undefined);
  };

  // Render Component
  return (
    <Paper elevation={0} sx={{ height: '60vh', width: '100%', mt: '0.5rem' }}>
      {isLoading ? (
        <DataGridSkeleton />
      ) : (
        <DataGrid
          className={classes.customScrollbar}
          rows={isLoading ? [] : props.insurancesData}
          columns={columns}
          getRowId={getRowId}
          localeText={localeText}
          loading={props.promiseInProgress}
          slotProps={{
            cell: { style: { outline: 'none' } },
          }}
          slots={{
            noRowsOverlay: () => <CustomNoRowsOverlay isLoading={isLoading} />,
            toolbar: CustomToolbar,
            pagination: CustomPagination,
          }}
          pagination
          initialState={{
            sorting: {
              sortModel: [{ field: 'insuranceExternalId', sort: 'asc' }],
            },
            pagination: { paginationModel: { pageSize: 10, page: 0 } },
          }}
          pageSizeOptions={[5, 10, 20]}
          filterMode="client"
          sortingMode="client"
        />
      )}
      {/* Modal for showing insurnace details */}
      <Modal open={insuranceDetailsOpen} onClose={handleClose}>
        <Box
          sx={(theme) => ({
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: theme.palette.mode === 'dark' ? '#1C1C1C' : 'background.paper',
            boxShadow: 24,
            borderRadius: 1,
            p: 2,
          })}
        >
          {selectedRow ? (
            <InsuranceDetails insurance={selectedRow} setEditInsuranceDialog={props.setEditInsuranceDialog} />
          ) : (
            <Typography>{noRecordsToDisplayTxt}</Typography>
          )}
          <Button onClick={handleClose} sx={{ mt: 1 }} variant="contained">
            {closeTxt}
          </Button>
        </Box>
      </Modal>
      {/* Modal for showing insurnace invoice */}
      <Modal open={insuranceInvoiceOpen} onClose={handleClose}>
        <Box
          sx={(theme) => ({
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: theme.palette.mode === 'dark' ? '#1C1C1C' : 'background.paper',
            boxShadow: 24,
            borderRadius: 1,
            p: 2,
          })}
        >
          {selectedRow ? (
            <InsuranceInvoices invoiceExternalId={selectedRow.insuranceExternalId} />
          ) : (
            <Typography>{noRecordsToDisplayTxt}</Typography>
          )}
          <Button onClick={handleClose} sx={{ mt: 2 }} variant="contained">
            {closeTxt}
          </Button>
        </Box>
      </Modal>
    </Paper>
  );
}
