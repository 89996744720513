import React from 'react';
import { ChipProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

import ColorChip from '../../../assets/ColorChip';
import { InsuranceInvoiceStatusEnum } from '../../../../generated';

type InsuranceStatusChipProps = ChipProps & { insuranceInvoiceStatus?: InsuranceInvoiceStatusEnum };

/**
 * Show the insurance status as chip
 * @param props the status
 */
export default function InsuranceInvoiceStatusChip(props: InsuranceStatusChipProps): JSX.Element {
  const { insuranceInvoiceStatus, ...other } = props;

  // Trnaslations
  const { t } = useTranslation();
  const paidTxt = t('paid');
  const billedTxt = t('billed');
  const pendingTxt = t('pending');
  const canceledTxt = t('canceled');

  let chipColor: 'grey' | 'green' | 'orange' = 'green';
  let label = '';
  switch (insuranceInvoiceStatus) {
    case InsuranceInvoiceStatusEnum.Paid: {
      chipColor = 'green';
      label = paidTxt;
      break;
    }
    case InsuranceInvoiceStatusEnum.Billed: {
      chipColor = 'orange';
      label = billedTxt;
      break;
    }
    case InsuranceInvoiceStatusEnum.Pending: {
      chipColor = 'orange';
      label = pendingTxt;
      break;
    }
    case InsuranceInvoiceStatusEnum.Canceled: {
      chipColor = 'grey';
      label = canceledTxt;
      break;
    }
  }

  return <ColorChip chipColor={chipColor} label={label} {...other} />;
}
