import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid2';

import { InsuranceInvoiceDto } from '../../../../generated';
import { DetailsBlock } from '../../../assets/details/DetailsBlock';
import { DetailsEntry } from '../../../assets/details/DetailsEntry';
import { NumberFormatUnitsText } from '../../../assets/numberformat/NumberFormatUnits';
import { formatDateTimeToGermanStyle, formatDateToGermanStyle } from '../../../../common/format';

export function InsuranceInvoiceDetails(props: { insuranceInvoice: InsuranceInvoiceDto }): JSX.Element {
  // Trnaslations
  const { t } = useTranslation();
  const createdTxt = t('created');
  const updatedTxt = t('updated');
  const timestampsTxt = t('timestamps');
  const invoiceDateTxt = t('insurance.invoiceDate');
  const paymentDateTxt = t('insurance.paymentDate');
  const invoiceDetailsTxt = t('insurance.invoiceDetails');
  const billedActivityUnitsTxt = t('insurance.billedActivityUnits');
  const recordedActivityUnitsTxt = t('insurance.recordedActivityUnits');

  return (
    <div>
      {/* <Grid container direction="column" spacing={1}> */}
      <Grid container direction="row" spacing={4} sx={{ ml: '3rem' }}>
        <Grid size={{ md: 6, xs: 12 }}>
          <DetailsBlock title={invoiceDetailsTxt}>
            <DetailsEntry property={invoiceDateTxt}>
              {formatDateToGermanStyle(props.insuranceInvoice.invoiceDetails.invoiceDate)}
            </DetailsEntry>
            <DetailsEntry property={paymentDateTxt}>
              {formatDateToGermanStyle(props.insuranceInvoice.invoiceDetails.paymentDate)}
            </DetailsEntry>
            <DetailsEntry property={billedActivityUnitsTxt}>
              <NumberFormatUnitsText value={props.insuranceInvoice.invoiceDetails.billedActivityUnits} />
            </DetailsEntry>
            <DetailsEntry property={recordedActivityUnitsTxt}>
              <NumberFormatUnitsText value={props.insuranceInvoice.invoiceDetails.recordedActivityUnits} />
            </DetailsEntry>
          </DetailsBlock>
        </Grid>
        <Grid size={{ md: 6, xs: 12 }}>
          <DetailsBlock title={timestampsTxt}>
            <DetailsEntry property={createdTxt}>
              {formatDateTimeToGermanStyle(props.insuranceInvoice.created)}
            </DetailsEntry>
            <DetailsEntry property={updatedTxt}>
              {formatDateTimeToGermanStyle(props.insuranceInvoice.updated)}
            </DetailsEntry>
          </DetailsBlock>
        </Grid>
      </Grid>
      {/* // </Grid> */}
    </div>
  );
}
