import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Edit } from '@mui/icons-material';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { Box, Button, IconButton, List, ListItem, ListItemText, Modal, Paper, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import { NumberFormatEurText } from '../../assets/numberformat/NumberFormatEur';
import { formatCordaX500Name } from '../../../common/format';
import { getNetworkApi } from '../../../common/keycloak';
import { CordaX500NameDto, MachineDto } from '../../../generated';
import { useConfig } from '../../../common/config';
import { MachineDetails } from './MachineDetails';
import {
  CustomLocaleText,
  CustomNoRowsOverlay,
  CustomPagination,
  CustomToolbar,
} from '../../assets/x-data-grid/helpers';
import { useStyles } from '../../Styles';
import DataGridSkeleton from '../../assets/DataGridSkeleton';

export function MachinesTable(props: {
  machinesData: MachineDto[];
  promiseInProgress: boolean;
  setEditMachineDialog: (open: { open: boolean; machine: MachineDto }) => void;
}): JSX.Element {
  const { promiseInProgress } = usePromiseTracker({ area: 'my-identity', delay: 200 });
  const isLoading = props.promiseInProgress || promiseInProgress;
  const [myIdentity, setMyIdentity] = useState('');
  const { machinesPage } = useConfig();
  const [selectedRow, setSelectedRow] = useState<MachineDto>();
  const [isModalOpen, setModalOpen] = useState(false);
  const getRowId = useCallback((row: MachineDto) => row.machineId, []);
  const localeText = CustomLocaleText();
  const classes = useStyles();

  // Trnaslations
  const { t } = useTranslation();
  const editTxt = t('edit');
  const closeTxt = t('closeBtn');
  const insurersTxt = t('insurers');
  const machineOwnerTxt = t('machineOwner');
  const detailsTxt = t('details');
  const machineUserTxt = t('machineUser');
  const machineNameTxt = t('machineName');
  const pricePerUnitTxt = t('pricePerUnit');
  const paymentProviderTxt = t('paymentProvider');
  const paymentThresholdTxt = t('paymentThreshold');
  const noRecordsToDisplayTxt = t('noRecordsToDisplay');

  // Get my identity to show edit buttons only when machineOwner of machine is equal to it
  useEffect(() => {
    if (!machinesPage.canEditMachines) return;

    const getIdentity = async function () {
      const networkApi = await getNetworkApi();
      const currentNode = await networkApi.getActiveNode();

      setMyIdentity(formatCordaX500Name(currentNode.identity));
    };

    trackPromise(getIdentity(), 'my-identity');
  }, []);

  // Define the columns
  const columns: GridColDef<MachineDto>[] = [
    {
      field: 'machineName',
      headerName: machineNameTxt,
      flex: 1,
      valueGetter: (value) => {
        if (!value) return 'NA';
        return value;
      },
      sortable: true,
    },
    {
      field: 'pricePerUnit',
      headerName: pricePerUnitTxt,
      align: 'center',
      flex: 0.75,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) return 'NA';
        return <NumberFormatEurText value={params.value} />;
      },
      sortable: true,
    },
    {
      field: 'paymentThreshold',
      headerName: paymentThresholdTxt,
      align: 'center',
      flex: 0.75,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) return 'NA';
        return <NumberFormatEurText value={params.value} />;
      },
      sortable: true,
    },
    {
      field: 'machineOwner',
      headerName: machineOwnerTxt,
      flex: 1,
      valueGetter: (value) => {
        if (!value) return 'NA';
        return formatCordaX500Name(value);
      },
      sortable: true,
    },
    {
      field: 'machineUser',
      headerName: machineUserTxt,
      flex: 1,
      valueGetter: (value) => {
        if (!value) return 'NA';
        return formatCordaX500Name(value);
      },
      sortable: true,
    },
    {
      field: 'paymentProvider',
      headerName: paymentProviderTxt,
      flex: 1,
      valueGetter: (value) => {
        if (!value) return 'NA';
        return formatCordaX500Name(value);
      },
      sortable: true,
    },
    {
      field: 'insurers',
      headerName: insurersTxt,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) return 'NA';

        return (
          <List>
            {params.value.map((insurer: CordaX500NameDto, index: number) => {
              return (
                <ListItem dense disableGutters key={index + 10000}>
                  <ListItemText>{formatCordaX500Name(insurer)}</ListItemText>
                </ListItem>
              );
            })}
          </List>
        );
      },
      sortable: true,
    },
    {
      field: 'details',
      headerName: detailsTxt,
      flex: 0.4,
      width: 80,
      align: 'center',
      renderCell: (cellvalue: GridRenderCellParams) => (
        <ReceiptIcon
          sx={{
            cursor: 'pointer',
            fontSize: 22,
            color: '#4caf50',
            '&:hover': {
              color: '#008CBA',
            },
          }}
          onClick={() => handleRowClick(cellvalue.row)}
        />
      ),
    },
  ];

  // Show Edit button only when myIdentity equals machineOwner of the machine
  if (machinesPage.canEditMachines) {
    columns.push({
      field: 'editMachine',
      headerName: editTxt,
      flex: 0.5,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: GridRenderCellParams) => {
        if (myIdentity != formatCordaX500Name(params.row.machineOwner)) return;
        return (
          <>
            <IconButton
              onClick={() => {
                props.setEditMachineDialog({ open: true, machine: params.row });
              }}
            >
              <Edit />
            </IconButton>
          </>
        );
      },
    });
  }

  // Handle row click to show details in the modal
  const handleRowClick = (rowData: MachineDto) => {
    setSelectedRow(rowData);
    setModalOpen(true);
  };

  // Close the modal
  const handleClose = () => {
    setModalOpen(false);
    setSelectedRow(undefined);
  };

  // Return the main component content
  return (
    <Paper elevation={0} sx={{ height: '60vh', width: '100%', mt: '0.5rem' }}>
      {isLoading ? (
        <DataGridSkeleton />
      ) : (
        <DataGrid
          className={classes.customScrollbar}
          rows={isLoading ? [] : props.machinesData}
          columns={columns}
          getRowId={getRowId}
          localeText={localeText}
          slotProps={{
            cell: { style: { outline: 'none' } },
          }}
          slots={{
            noRowsOverlay: () => <CustomNoRowsOverlay isLoading={isLoading} />,
            toolbar: CustomToolbar,
            pagination: CustomPagination,
          }}
          pagination
          initialState={{
            sorting: { sortModel: [{ field: 'machineName', sort: 'asc' }] },
            pagination: { paginationModel: { pageSize: 10, page: 0 } },
          }}
          pageSizeOptions={[5, 10, 20]}
          filterMode="client"
          sortingMode="client"
        />
      )}
      {/* Modal for showing row details */}
      <Modal open={isModalOpen} onClose={handleClose}>
        <Box
          sx={(theme) => ({
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: theme.palette.mode === 'dark' ? '#1C1C1C' : 'background.paper',
            boxShadow: 24,
            borderRadius: 1,
            p: 2,
          })}
        >
          {selectedRow ? <MachineDetails machine={selectedRow} /> : <Typography>{noRecordsToDisplayTxt}</Typography>}
          <Button onClick={handleClose} sx={{ mt: 1 }} variant="contained">
            {closeTxt}
          </Button>
        </Box>
      </Modal>
    </Paper>
  );
}
