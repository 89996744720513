import React from 'react';
import get from 'lodash/get';
import { Chip, ChipProps, useTheme } from '@mui/material';

type ColorChipProps = ChipProps & {
  chipColor: 'grey' | 'green' | 'orange' | 'red';
};

/**
 * Colored chips in grey, green, orange and red
 * @param props the default ColorChips properties with additional `chipColor` property
 */
export default function ColorChip(props: ColorChipProps): JSX.Element {
  const { chipColor, ...other } = props;

  const theme = useTheme();

  const style = {
    gray: {
      backgroundColor: theme.palette.secondary.light,
    },
    green: {
      backgroundColor: theme.palette.success.light,
    },
    orange: {
      backgroundColor: theme.palette.warning.light,
    },
    red: {
      backgroundColor: theme.palette.error.light,
    },
  };

  return <Chip style={get(style, chipColor)} {...other} />;
}
