import React, { ReactNode } from 'react';
import Typography from '@mui/material/Typography';
import { IconButton } from '@mui/material';
import { Edit } from '@mui/icons-material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';

export const DetailsBlock: React.FunctionComponent<{
  title?: string;
  hasEditButton?: boolean;
  onEditButtonClick?: () => void;
  keyColumnWidth?: string;
  children?: ReactNode;
}> = function (props) {
  return (
    <>
      <Typography gutterBottom variant="subtitle2" sx={{ textAlign: 'left' }}>
        {props.title}
        {props.hasEditButton && (
          <IconButton onClick={props.onEditButtonClick}>
            <Edit fontSize="small" />
          </IconButton>
        )}
      </Typography>
      <Table padding="none">
        <colgroup>
          <col style={{ width: props.keyColumnWidth || '180px' }} />
          <col style={{}} />
        </colgroup>
        <TableBody>{props.children}</TableBody>
      </Table>
    </>
  );
};
