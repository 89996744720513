import React from 'react';
import { useTranslation } from 'react-i18next';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { List, ListItem, ListItemIcon, ListItemText, Paper } from '@mui/material';
import Grid from '@mui/material/Grid2';

import {
  InsuranceDetailsDto,
  InsuranceDto,
  InsuranceMachineDetailsDto,
  InsurancePaymentDetailsDto,
  InsurancePayPerUseDetailsDto,
} from '../../../generated';
import { useConfig } from '../../../common/config';
import { formatDateTimeToGermanStyle } from '../../../common/format';
import { InsuranceDialogState } from './InsuranceDialog';
import { DetailsBlock } from '../../assets/details/DetailsBlock';
import { DetailsEntry } from '../../assets/details/DetailsEntry';
import { NumberFormatEurText } from '../../assets/numberformat/NumberFormatEur';
import { NumberFormatUnitsText } from '../../assets/numberformat/NumberFormatUnits';
import { useStyles } from '../../Styles';

export function InsuranceDetails(props: {
  insurance: InsuranceDto;
  setEditInsuranceDialog: (open: { open: InsuranceDialogState; insurance: InsuranceDto }) => void;
}): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.divTable}>
      <Grid container direction="column" spacing={1}>
        <Grid>
          <Paper className={classes.papertable}>
            <Grid container direction="row" spacing={4}>
              <Grid size={{ md: 6, xs: 12 }}>
                <InsuranceDetailsBlock
                  insuranceDetails={props.insurance.insuranceDetails}
                  onEditButtonClick={() =>
                    props.setEditInsuranceDialog({
                      open: InsuranceDialogState.InsuranceDetailsDialog,
                      insurance: props.insurance,
                    })
                  }
                />
              </Grid>
              <Grid size={{ md: 6, xs: 12 }}>
                <MachineDetailsBlock
                  machineDetails={props.insurance.machineDetails}
                  machineId={props.insurance.machineId}
                  onEditButtonClick={() =>
                    props.setEditInsuranceDialog({
                      open: InsuranceDialogState.MachineDetailsDialog,
                      insurance: props.insurance,
                    })
                  }
                />
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={4}>
              <Grid size={{ md: 6, xs: 12 }}>
                <PaymentDetailsBlock
                  paymentDetails={props.insurance.paymentDetails}
                  onEditButtonClick={() =>
                    props.setEditInsuranceDialog({
                      open: InsuranceDialogState.PaymentDetailsDialog,
                      insurance: props.insurance,
                    })
                  }
                />
              </Grid>
              <Grid size={{ md: 6, xs: 12 }}>
                <PayPerUseDetailsBlock
                  payperUseDetails={props.insurance.payPerUseDetails}
                  onEditButtonClick={() =>
                    props.setEditInsuranceDialog({
                      open: InsuranceDialogState.PayPerUseDetailsDialog,
                      insurance: props.insurance,
                    })
                  }
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {/* <Grid>
          <Paper className={classes.papertable}>
            <InsuranceInvoices invoiceExternalId={props.insurance.insuranceExternalId} />
          </Paper>
        </Grid> */}
      </Grid>
    </div>
  );
}

const InsuranceDetailsBlock = function (props: {
  insuranceDetails: InsuranceDetailsDto;
  onEditButtonClick: () => void;
}) {
  const { insurancePage } = useConfig();
  // Trnaslations
  const { t } = useTranslation();
  const typeTxt = t('type');
  const insuranceDetailsTxt = t('insuranceDetails.insuranceDetails');
  const startTimestampTxt = t('insuranceDetailsDialog.startTimestamp');
  const endTimestampTxt = t('insuranceDetailsDialog.endTimestamp');
  const insuredAmountTxt = t('insuranceDetailsDialog.insuredAmount');
  const excessAmountTxt = t('insuranceDetailsDialog.excessAmount');
  const insuredRiskTxt = t('insuranceDetailsDialog.insuredRisk');

  return (
    <DetailsBlock
      title={insuranceDetailsTxt}
      hasEditButton={insurancePage.canEditInsurances}
      onEditButtonClick={props.onEditButtonClick}
    >
      <DetailsEntry property={typeTxt}>{props.insuranceDetails.type}</DetailsEntry>
      <DetailsEntry property={startTimestampTxt}>
        {formatDateTimeToGermanStyle(props.insuranceDetails.startTimestamp)}
      </DetailsEntry>
      <DetailsEntry property={endTimestampTxt}>
        {formatDateTimeToGermanStyle(props.insuranceDetails.endTimestamp)}
      </DetailsEntry>
      <DetailsEntry property={insuredAmountTxt}>
        <NumberFormatEurText value={props.insuranceDetails.insuredAmount} />
      </DetailsEntry>
      <DetailsEntry property={excessAmountTxt}>
        <NumberFormatEurText value={props.insuranceDetails.excessAmount} />
      </DetailsEntry>
      <DetailsEntry property={insuredRiskTxt}>
        <List dense disablePadding>
          {props.insuranceDetails.insuredRisks?.map((risk, index) => {
            return (
              <ListItem disableGutters style={{ paddingTop: 0, paddingBottom: 0 }} key={index + 10000}>
                <ListItemIcon style={{ minWidth: 0, paddingRight: '5px' }}>
                  <CheckCircleIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>{risk}</ListItemText>
              </ListItem>
            );
          })}
        </List>
      </DetailsEntry>
    </DetailsBlock>
  );
};

const MachineDetailsBlock = function (props: {
  machineDetails: InsuranceMachineDetailsDto;
  machineId: string;
  onEditButtonClick: () => void;
}) {
  const { insurancePage } = useConfig();
  // Trnaslations
  const { t } = useTranslation();
  const typeTxt = t('type');
  const machineIdTxt = t('machineId');
  const machineDetailsTxt = t('machineDetails.machineDetails');
  const manufacturerTxt = t('machineDetailsDialog.manufacturer');
  const serialNumberTxt = t('machineDetailsDialog.serialNumber');
  const manufacturingDateTxt = t('machineDetailsDialog.manufacturingDate');
  const locationTxt = t('machineDetailsDialog.location');

  return (
    <DetailsBlock
      title={machineDetailsTxt}
      hasEditButton={insurancePage.canEditInsurances}
      onEditButtonClick={props.onEditButtonClick}
    >
      <DetailsEntry property={machineIdTxt}>{props.machineId}</DetailsEntry>
      <DetailsEntry property={typeTxt}>{props.machineDetails.type}</DetailsEntry>
      <DetailsEntry property={manufacturerTxt}>{props.machineDetails.manufacturer}</DetailsEntry>
      <DetailsEntry property={serialNumberTxt}>{props.machineDetails.serialNumber}</DetailsEntry>
      <DetailsEntry property={manufacturingDateTxt}>{props.machineDetails.manufacturingDate}</DetailsEntry>
      <DetailsEntry property={locationTxt}>{props.machineDetails.location}</DetailsEntry>
    </DetailsBlock>
  );
};

const PaymentDetailsBlock = function (props: {
  paymentDetails: InsurancePaymentDetailsDto;
  onEditButtonClick: () => void;
}) {
  const { insurancePage } = useConfig();
  // Trnaslations
  const { t } = useTranslation();
  const typeTxt = t('type');
  const methodTxt = t('paymentDetailsDialog.method');
  const intervalTxt = t('paymentDetailsDialog.interval');
  const paymentDetailsTxt = t('insuranceDetails.paymentDetails');

  return (
    <DetailsBlock
      title={paymentDetailsTxt}
      hasEditButton={insurancePage.canEditInsurances}
      onEditButtonClick={props.onEditButtonClick}
    >
      <DetailsEntry property={methodTxt}>{props.paymentDetails.method}</DetailsEntry>
      <DetailsEntry property={typeTxt}>{props.paymentDetails.type}</DetailsEntry>
      <DetailsEntry property="Basis">{props.paymentDetails.basis}</DetailsEntry>
      <DetailsEntry property={intervalTxt}>
        <span style={{ textTransform: 'capitalize' }}>{props.paymentDetails.interval?.toLowerCase()}</span>
      </DetailsEntry>
    </DetailsBlock>
  );
};

const PayPerUseDetailsBlock = function (props: {
  payperUseDetails: InsurancePayPerUseDetailsDto;
  onEditButtonClick: () => void;
}) {
  const { insurancePage } = useConfig();
  // Trnaslations
  const { t } = useTranslation();
  const ppuDetailsTxt = t('insuranceDetails.ppuDetails');
  const annualExpectedAmountTxt = t('payPerUseDetailsDialog.annualExpectedAmount');
  const annualBaseAmountTxt = t('payPerUseDetailsDialog.annualBaseAmount');
  const annualCapAmountTxt = t('payPerUseDetailsDialog.annualCapAmount');
  const annualExpectedActivityTxt = t('payPerUseDetailsDialog.annualExpectedActivity');
  const pricePerUnitTxt = t('pricePerUnit');

  return (
    <DetailsBlock
      title={ppuDetailsTxt}
      hasEditButton={insurancePage.canEditInsurances}
      onEditButtonClick={props.onEditButtonClick}
    >
      <DetailsEntry property={annualExpectedAmountTxt}>
        <NumberFormatEurText value={props.payperUseDetails.annualExpectedAmount} />
      </DetailsEntry>
      <DetailsEntry property={annualBaseAmountTxt}>
        <NumberFormatEurText value={props.payperUseDetails.annualBaseAmount} />
      </DetailsEntry>
      <DetailsEntry property={annualCapAmountTxt}>
        <NumberFormatEurText value={props.payperUseDetails.annualCapAmount} />
      </DetailsEntry>
      <DetailsEntry property={annualExpectedActivityTxt}>
        <NumberFormatUnitsText value={props.payperUseDetails.annualExpectedActivity} />
      </DetailsEntry>
      <DetailsEntry property={pricePerUnitTxt}>
        <NumberFormatEurText value={props.payperUseDetails.pricePerUnit} />
      </DetailsEntry>
    </DetailsBlock>
  );
};
