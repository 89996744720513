import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { useConfig } from '../../common/config';
import { RouteType } from '../pages/routes';
import { ListItemButton } from '@mui/material';

/**
 * Defines the routes an the navigation bar on the left hand side.
 */
export const RoutingItems = function (): JSX.Element {
  const location = useLocation();
  const { routes } = useConfig();

  return (
    <div>
      {routes.map((route: RouteType, index: number) => (
        <ListItemButton key={index} component={Link} to={route.path} selected={location.pathname === route.path}>
          <ListItemIcon style={{ width: 40, fontSize: 30 }}>
            <route.icon />
          </ListItemIcon>
          <ListItemText primary={route.label} />
        </ListItemButton>
      ))}
    </div>
  );
};
