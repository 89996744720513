import React from 'react';
import Typography from '@mui/material/Typography';

/**
 * This compontent defines the table title component.
 * @param props titlename
 */
export function Title(props: { children: string }): JSX.Element {
  return (
    <Typography component="h2" variant="h6" color="primary" gutterBottom>
      {props.children}
    </Typography>
  );
}
