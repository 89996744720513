import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  gridPageCountSelector,
  GridPagination,
  GridToolbarExport,
  useGridApiContext,
  useGridSelector,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { TablePaginationProps } from '@mui/material/TablePagination';
import MuiPagination from '@mui/material/Pagination';

import Spinner from '../Spinner';

// Custom text and spinner when there is no record
export function CustomNoRowsOverlay({ isLoading }: { isLoading: boolean }) {
  // Translations
  const { t } = useTranslation();
  const noRecordsToDisplayTxt = t('noRecordsToDisplay');

  return (
    <Box sx={{ height: 100 }}>
      {isLoading ? (
        <Spinner />
      ) : (
        <Typography sx={{ textAlign: 'center', padding: 2 }}>{noRecordsToDisplayTxt}</Typography>
      )}
    </Box>
  );
}

// Custom pagination
function Pagination({
  page,
  onPageChange,
  className,
}: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>) {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <MuiPagination
      color="primary"
      className={className}
      count={pageCount}
      page={page + 1}
      onChange={(event, newPage) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onPageChange(event as any, newPage - 1);
      }}
    />
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function CustomPagination(props: any) {
  return <GridPagination ActionsComponent={Pagination} {...props} />;
}

// Custom text for the data grid
export function CustomLocaleText() {
  // Translations
  const { t } = useTranslation();
  const showColumnsTxt = t('tableToolbar.showColumns');
  const showColumnsTooltipTxt = t('tableToolbar.showColumnsTooltip');
  const filterTxt = t('tableToolbar.filter');
  const filterTooltipTxt = t('tableToolbar.filterTooltip');
  const rowsPerPageTxt = t('tableToolbar.rowsPerPage');

  return {
    toolbarColumns: showColumnsTxt,
    toolbarColumnsLabel: showColumnsTooltipTxt,
    toolbarFilters: filterTxt,
    toolbarFiltersTooltipShow: filterTooltipTxt,
    MuiTablePagination: { labelRowsPerPage: rowsPerPageTxt },
  };
}

// Custom toolbar for the data grid
export function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <Box sx={{ flexGrow: 1 }} />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}
